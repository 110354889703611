import { useState } from 'react';
import {
    Routes,
    Route,
    Outlet,
    Link,
    useMatch,
    NavLink,
    useResolvedPath,
} from "react-router-dom";
import KofiButton from "kofi-button"
import type { LinkProps } from "react-router-dom";
import AdminNavbar from './AdminNavbar';
import { Icon, InlineIcon } from '@iconify/react';
import WebflowFooter from './WebflowFooter';

export default function Sidebar() {
    const [showSidebar, setShowSidebar] = useState('-left-64');
    let activeStyle = {
        textDecoration: "underline",
    };

    let activeClassName = "w-11/12 flex  w-gap-4 text-sm text-white-700 px-3 py-3 rounded-lg bg-primary text-white hover:opacity-80 shadow-md";

    let inActiveClassName = "w-11/12 flex w-gap-4 text-sm text-gray-700 font-light px-3  py-3 rounded-lg";

    return (

        <>
            <AdminNavbar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
            />
            <div
                className={`h-screen bg-light fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap items-start overflow-hidden shadow-xl w-64 z-10 transition-all duration-300`}
            >
                <div className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
                    <div className='flex items-center justify-center bg-secondary h-24'>

                        <a
                            href="https://www.friendlyfoodtracker.com"
                            target="_blank"
                            rel="noreferrer"
                            className="mt-2 text-center w-full inline-block"
                        >
                            <img src={require('../assets/images/logo_light_med.png')} />
                        </a>
                    </div>
                    <div className="flex flex-col ml-10 mt-10">

                        <nav>
                            <ul className="-ml-5 flex-col min-w-full flex ">

                                <NavLink
                                    to="/chat"
                                    className={({ isActive }) =>
                                        isActive ? activeClassName : inActiveClassName
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <li className="rounded-lg">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <InlineIcon icon="mdi:chat" className='mr-3' />Chat

                                        </div>

                                    </li>
                                </NavLink>
                                <NavLink
                                    to="/dashboard"
                                    className={({ isActive }) =>
                                        isActive ? activeClassName : inActiveClassName
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <li className="rounded-lg">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <InlineIcon icon="mdi:view-dashboard" className='mr-3' />Dashboard

                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink
                                    to="/whatsapp"
                                    className={({ isActive }) =>
                                        isActive ? activeClassName : inActiveClassName
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <li className="rounded-lg">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <InlineIcon icon="mdi:whatsapp" className='mr-3' />Whatsapp Settings

                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink
                                    to="/profile"
                                    className={({ isActive }) =>
                                        isActive ? activeClassName : inActiveClassName
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <li className="rounded-lg">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <InlineIcon icon="mdi:account-heart" className='mr-3' />Profile

                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink
                                    to="/about"
                                    className={({ isActive }) =>
                                        isActive ? activeClassName : inActiveClassName
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <li className="rounded-lg">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <InlineIcon icon="mdi:abacus" className='mr-3' />About DQS

                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink
                                    to="/lookup"
                                    className={({ isActive }) =>
                                        isActive ? activeClassName : inActiveClassName
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <li className="rounded-lg">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <InlineIcon icon="mdi:magnify" className='mr-3' />Food Lookup

                                        </div>
                                    </li>
                                </NavLink>
                                <NavLink
                                    to="/contact"
                                    className={({ isActive }) =>
                                        isActive ? activeClassName : inActiveClassName
                                    }
                                    style={{ textDecoration: 'none' }}
                                >
                                    <li className="rounded-lg">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                            <InlineIcon icon="mdi:email" className='mr-3' />Contact Us

                                        </div>
                                    </li>
                                </NavLink>

                                {/*  <li className="rounded-lg">


                                    <div className='flex items-center gap-4 text-sm text-gray-700 font-light py-3 rounded-lg'>
  
                                    <InlineIcon icon="mdi:help-circle" className="ml-3"/>
                                        <a style={{ textDecoration: 'none' }} target="_parent" className="-ml-1" href="https://friendlyfoodtracker.notion.site/fb37902ac4f4425894c52c8bf1078d7c?v=3a7e5c7f63ff49868f80ce212eaea729">
                                            Support
                                            
                                        </a>
                               
                                        </div>
                                        
                                </li> */}
                                <hr className="my-2 border-blue-gray-100" />
                               
                               
                                <a
                                    className="w-11/12 flex w-gap-4 text-sm text-white-700 px-3 py-3 rounded-lg bg-pinky text-white hover:opacity-80 shadow-md font-ibold"
                                    href="https://ko-fi.com/friendlyfoodtracker"
                                    target="_blank"
                                > <li className="rounded-lg">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <InlineIcon icon="simple-icons:kofi" className='mr-3' />
                                    Support the Site
                                    </div>
                                </li>
                                </a>
                              
                            </ul>
                        </nav>



                    </div>
                </div>

            </div>
        </>
    );
}
