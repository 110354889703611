
export default function WebflowFooter() {

  return (
<>
    <section className="footer-light-2">
    <div className="container-4">
      <div className="footer-wrapper-two">
      <img src={require("../assets/images/logo_light.png")} alt="Small White FFT Logo" />
        <div className="footer-block-two">
          <div className="footer-title">Navigation</div>
          <a href="https://www.friendlyfoodtracker.com/contact" className="footer-link-two">Contact Us</a>
        </div>
        <div className="footer-block-two">
          <div className="footer-title">Important Stuff</div>
          <a href="https://www.friendlyfoodtracker.com/privacy" className="footer-link-two">Privacy Policy</a>
          <a href="https://www.friendlyfoodtracker.com/terms-of-service" className="footer-link-two">Terms of Service</a>
        </div>
        <div className="footer-form w-form">
          
          
        </div>
      </div>
      <div className="footer-divider-two"></div>
      <div className="footer-bottom">
        <div className="footer-copyright">© 2024 Friendly Food Tracker. All rights reserved</div>
      </div>
    </div>
  </section>
  </>
  )
}