import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { ThreeDots } from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import {
    Card,
    CardHeader,
    CardBody, Typography,
    Button, Popover,
    PopoverHandler,
    PopoverContent
} from "@material-tailwind/react";
import { Bar, BarChart, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { Icon } from '@iconify/react';


export default function MealTypePointsChart() {
    const { promiseInProgress } = usePromiseTracker();
    const [pointsChartData, setPointsChartData] = useState([]);
    const { user } = UserAuth();

    useEffect(() => {
        if (user.accessToken) {
            const token = user.getIdToken()
            const fetchRawData = async () => {

                const axios_config = {
                    method: 'GET',
                    url: process.env.REACT_APP_API_URL + `/api/v1/meal-type-points-chart`,
                    headers: {
                        "Authorization": `Bearer ${user.accessToken}`
                    },
                    params: {
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                  };

                const response = await trackPromise(axios(axios_config))

                const data = await response.data;

                console.log("Points by Meal Type:", data)

                fetchPointsChartData(data);

            }
            // Fetch 'feed' data then aggregate it
            fetchRawData();

        }
    }, [user]);

    const fetchPointsChartData = (data) => {
        if (data.length > 0) {
            setPointsChartData(data.sort((a, b) => (a.sort_value > b.sort_value) ? 1 : -1));
        }
    }

    const LoadingIndicator = props => {
        return (
            promiseInProgress &&
            <div
                style={{
                    width: "100%",
                    height: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
            </div>
        );
    };

    return (
        <>
            <div>
                <Card>
                    <CardHeader className="text-left p-4 bg-secondary">
                        <Typography variant="h6" className='text-light'>
                            Meal Type
                        </Typography>
                        <Typography variant="h4" className='text-light'>
                            Points Total
                            <Popover placement="bottom-start">
                                <PopoverHandler>
                                    <Button size="sm" variant="text" color="white" className='align-sub' >
                                        <Icon icon="material-symbols:info" width="24" color="white" />
                                    </Button>
                                </PopoverHandler>
                                <PopoverContent className='border-2 border-primary'>
                                    <Typography variant="h5" divider>Meal Type Points</Typography>
                                    Use this chart to see which meals you've scored most highly on in the last two weeks.{<br />}{<br />}This is based on the sum of points per day, not average.
                                </PopoverContent>
                            </Popover>
                        </Typography>
                    </CardHeader>
                    <CardBody>
                        <ResponsiveContainer width='100%' aspect={1.5 / 1.0}>
                            {
                                (promiseInProgress === true) ? <LoadingIndicator /> :
                                    (pointsChartData.length === 0) ? <Typography variant="h5" color="gray">No data to display</Typography> :
                                    <BarChart data={pointsChartData}>
                                        <Bar name="points" dataKey="points_actual" fill="url(#colorUv)" />
                                        <XAxis tick={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }} dataKey="meal_type" />
                                        <YAxis domain={[0, 'auto']} width={20} tick={{ fontFamily: 'Roboto, sans-serif', fontSize: '12px' }} />
                                        <Tooltip
                                            cursor={{ fill: 'rgba(84,182,187,0.1' }}

                                            wrapperStyle={{ outline: "none" }}
                                            contentStyle={{ fontSize: '12px', outline: 'none' }}
                                            formatter={(value) => new Intl.NumberFormat('en-us', {
                                                maximumFractionDigits: 2,
                                                name: 'test'
                                            }).format(value)} />
                                        <defs>
                                            <linearGradient
                                                id="colorUv"
                                                x1="0"
                                                y1="0"
                                                x2="0"
                                                y2="100%"
                                                gradientUnits="userSpaceOnUse"
                                            >
                                                <stop offset="0" stopColor="#54b6bb" />
                                                <stop offset="1" stopColor="#fafafa" />
                                            </linearGradient>
                                        </defs>
                                    </BarChart>
                            }
                        </ResponsiveContainer>
                    </CardBody>
                </Card>
            </div>


        </>
    )
}
