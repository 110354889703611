// Contact
import axios from 'axios';
import { Firestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useForm } from 'react-hook-form'
import { UserAuth } from '../context/AuthContext'
import DialogflowMessenger from '../components/DialogflowMessenger';
import { Icon, InlineIcon } from '@iconify/react';
import {
  createUserProfile,
  displayUserSettings,
  updateUserProfile,
  addWeightValue,
} from '../functions/FirebaseCalls'

import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserId
} from '../context/AnalyticsContext';

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Avatar,
  Input,
  Textarea,
  Typography,
  Tabs,
  TabsHeader,
  TabPanel,
  TabsBody,
  Tab,
  Switch,
  Tooltip,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";

initAmplitude()

const Contact = () => {
  const { promiseInProgress } = usePromiseTracker();
  const { user, logOut } = UserAuth();
  const [dailyTarget, setDailyTarget] = useState('')
  const [currentWeight, setCurrentWeight] = useState('')
  const [userProfile] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const [size, setSize] = useState(null);
  const [comment, setComment] = useState('')
  const { register, reset } = useForm();
  const handleOpen = (value) => setSize(value);
  const [alertResponse, setAlertResponse] = useState('')
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false);

  setAmplitudeUserId(user.uid);


  const handleSubmit = async () => {
    
    const currentUnixTime = Math.floor(Date.now() / 1000);
    if (comment){
      try
      {
        setLoading(true)
        const response = await trackPromise(axios.get(process.env.REACT_APP_API_URL + `/api/v1/save-comments`, {
          params: {
            unixDate: currentUnixTime,
            timezoneOffset: new Date().getTimezoneOffset(),
            comment: comment,
          }, headers: { "Authorization": `Bearer ${user.accessToken}` }
        }))
  
        setComment('')
        setAlertResponse("Comment successfully sent")
  
      } catch (e) {
        setError(e.code)
        console.log(e.code)
      } finally {
        setLoading(false)
      }
    }
    }
 

  const setUserProfileData = async () => {
    await updateUserProfile(user.uid, displayName, dailyTarget, currentWeight);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (user.uid) {
        const response = await displayUserSettings(user.uid)
        setDisplayName(response.display_name)
        setDailyTarget(response.target_points)
        setCurrentWeight(response.current_weight)
      }
    }
    fetchData();
  }, [user]); // Or [] if effect doesn't need props or state


  const LoadingIndicator = props => {
    return (
      promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
      </div>
    );
  }

  return (
    <>
      <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url(https://images.unsplash.com/photo-1607478900766-efe13248b125?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80)] bg-cover	bg-center">

      </div>
      <Card className="mx-3 lg:mx-4">
        <CardBody className="p-4">
          <div className="flex items-center justify-between gap-6">
            <div className="flex items-center gap-6">
              <div>
              <Typography variant="h4" color="gray" className="mb-1">
              Contact Us
                </Typography>
                <Typography
                  variant="medium"
                  className="font-normal text-secondary"
                >
                  How to get in touch
                </Typography>
              </div>
            </div>
          </div>
          <div className='max-w-[600px] mx-left p-4 text-blue-gray-600'>

            <ul className="list-disc leading-8 ml-5">
              <li>Use the chat bot below </li>
              <li>Email us at <a href="mailto:support@friendlyfoodtracker.com">support@friendlyfoodtracker.com</a></li>
              <li>Fill in the form below</li>

            </ul>
            <form>
            {
              (promiseInProgress === true) ? <LoadingIndicator /> : (loading==false) ? <div className='flex flex-col py-2 my-2'>
                <Textarea value={comment} color='teal' onChange={(e) => setComment(e.target.value)} label="Comments, Questions, Feedback" />
              </div>:"OK"

            }

              
             

             

              <Button onClick={handleSubmit} className='bg-primary hover:opacity-80 text-light w-full p-4 my-2 text-white'>Save</Button>

            </form>

            {
              (error) ?  <div className="bg-teal-100 border border-red-400 rounded-b text-red-900 px-4 mt-5 py-1 shadow-md" role="alert">

              <p className="text-sm">{error}</p>
              </div> : 
              (alertResponse) ?
              <div className="bg-teal-100 border border-teal-400 rounded-b text-teal-900 px-4 mt-5 py-1 shadow-md" role="alert">

                <p className="text-sm">{alertResponse}</p>
              </div> : ""
              
              
                
            }



</div>
        </CardBody>
      </Card>


      <DialogflowMessenger />
    </>
  )
}

export default Contact