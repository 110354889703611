import React from 'react';
import {Navigate} from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

const ProtectedRoute = ({ children }) => {
  const {user} = UserAuth(); 
  
  console.log(window.location.pathname)
  if (!user) {
    console.log('User not logged in')
    return <Navigate to ='/'/>
  } else if (user.emailVerified==false)
  {
     return <Navigate to ='/email-verification'/>
  }
  return children
  }

export default ProtectedRoute