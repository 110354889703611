import React from 'react';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';

class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  testFunction = (message) => {
    console.log('Test:', message);
  }

  parse = (message) => {
    this.actionProvider.setLoading(true);

    const token = `${this.actionProvider.user.user.accessToken}`
    
    const axios_config = {
      method: 'POST',
      url: process.env.REACT_APP_API_URL + `/api/v1/dialog-flow-proxy`,
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${this.actionProvider.user.user.accessToken}`
      },
      data: {
        queryInput: {
          text: {
            text: message,
            languageCode: 'en-GB',
            timezoneOffset: new Date().getTimezoneOffset(),
          },
        },
      },
    };

    axios(axios_config).then((response) => {
        const message = response.data.payload[0].message
        const responseId = response.data.payload[0].responseId

        console.log('Retry Message:',  message);

        if (message === 'webhook-timeout') {
          console.log("webhook-timeout: retry started");
          console.log('responseId:', message);
          axios.post(
            process.env.REACT_APP_API_URL + `/api/v1/dialog-flow-proxy-retry`,
            {
              headers: { "Authorization": `Bearer ${this.actionProvider.user.user.accessToken}` },
              queryInput: {
                text: {
                  responseId: responseId,
                  timezoneOffset: new Date().getTimezoneOffset(),
                },
              },
            },
          ).then((response) => {

            const fulfillment = response.data.payload;
            this.actionProvider.setBotResponse(fulfillment);
            this.actionProvider.setLoading(false);
            console.log('response: ', response.data);
            console.log("webhook-timeout: retry success");
          })

        } else {
          const fulfillment = response.data.payload;
          this.actionProvider.setBotResponse(fulfillment);
          this.actionProvider.setLoading(false);
          console.log('response: ', response.data);
        }

      })
      .catch((error) => {
        console.log(error);
        this.actionProvider.setLoading(false);
      });
  };


}

export default MessageParser;
