import {
    Accordion, AccordionBody, AccordionHeader, Button, Card,
    CardBody, Typography, Collapse, CardHeader
} from "@material-tailwind/react";
import React, { Fragment, useEffect, useState } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { UserAuth } from '../context/AuthContext';
import DialogflowMessenger from '../components/DialogflowMessenger';
import ChatBot from '../components/Chatbot.jsx';
import axios from 'axios';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";



function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-5 w-5 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}

function Chat() {

    const { promiseInProgress } = usePromiseTracker();
    const { user, logOut } = UserAuth();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(1);
    const [initialMessage, setInitialMessage] = useState("");
    const [displayName, setDisplayName] = useState("Test");

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    var offset = 'tzOffsetMins=' + new Date().getTimezoneOffset();

    useEffect(() => {

        if (user.accessToken) {
            const token = user.getIdToken()
            const fetchRawData = async () => {

                const response = await trackPromise(axios.get(
                    process.env.REACT_APP_API_URL + `/api/v1/initial-call`,
                    {
                        headers: {
                            "Authorization": `Bearer ${user.accessToken}`
                        },
                    }
                ));

                const data = await response.data;

                setDisplayName(data.displayName)
                setInitialMessage(data.initialMessage)

            }

            fetchRawData();
        }
    }, [user]);


    const LoadingIndicator = props => {
        return (
            (loading === true) &&
            <div
                style={{
                    width: "100%",
                    height: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
            </div>
        );
    }

    return (
        <>
        <div className="bg-light">
            <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url(https://images.unsplash.com/photo-1574071318508-1cdbab80d002?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1738&q=80)] bg-cover	bg-center">

            </div>

            <Fragment>
                <Card className="mx-3 -mt-16 lg:mx-4">
                    <CardBody>
                        <div>
                            <Typography variant="h4" color="gray" className="mb-1">
                            Friendly Food Tracker
                            </Typography>
                            <Typography
                                variant="medium"
                                className="font-normal text-secondary"
                            >
                                Welcome to Friendly Food Tracker.  Log what you ate and get some instructions here...
                            </Typography>
                        </div>

                        <div>
                            <Accordion open={open === 0}
                                className="border-blue-gray-100  mb-2">
                                <AccordionHeader
                                    className="text-primary hover:opacity-80 text-md"
                                    onClick={() => handleOpen(1)}>
                                    Click here for some tips and examples
                                </AccordionHeader>
                                <AccordionBody>

                                    <div className="grid grid-cols-3 gap-4">
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-teal-400 p-2 text-sm text-white font-bold">Examples</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-orange-400 p-2 text-sm text-white font-bold">Stuff to Remember</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-pink-400 p-2 text-sm text-white font-bold">Limitations</div>

                                        <div className="border-2 rounded-lg flex justify-center items-center bg-teal-100 p-2 text-sm font-normal">"I had cornflakes, toast and coffee for breakfast"</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-orange-100 p-2 text-sm font-normal">You don't need too specific with food as it's the category of food we care about. 'Pasta' will work just as well as 'tagliatelle'</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-pink-100 p-2 text-whitetext-sm font-normal">We're still working on being clever enough to know that some meals contain several types of food, so in the meantime, log your curry as chicken, rice and naan</div>

                                        <div className="border-2 rounded-lg flex justify-center items-center bg-teal-100 p-2 text-sm font-normal">"For dinner last night I had salmon, rice and some salad"</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-orange-100 p-2 text-sm font-normal">Only log one meal type per entry. Ie, one entry for lunch and a separate entry for dinner</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-pink-100 p-2 text-sm font-normal">We're still working on sauces so for now don't log them.  This isn't ideal as obviously cheese sauce isn't healthy. We'll fix it soon - promise!</div>

                                        <div className="border-2 rounded-lg flex justify-center items-center bg-teal-100 p-2 text-sm font-normal">"I had wine and chocolate for a snack"</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-orange-100 p-2 text-sm font-normal">Anything wholegrain is healthy so if you're having wholegrain toast for example, log it as wholegrain toast. Same goes for brown rice...</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-pink-100 p-2 text-sm font-normal"></div>

                                        <div className="border-2 rounded-lg flex justify-center items-center bg-teal-100 p-2 text-sm font-normal">"I had a wholgrain bagel with scrambled egg for breakfast"</div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-orange-100 text-sm font-normal"></div>
                                        <div className="border-2 rounded-lg flex justify-center items-center bg-pink-100 text-sm font-normal"></div>

                                    </div>
                                </AccordionBody>
                            </Accordion>
                        </div>




                        <div>

                            {
                                (promiseInProgress === true) ? <LoadingIndicator /> : <ChatBot initialMessage={initialMessage} displayName={displayName} />

                            }

                        </div>
                    </CardBody>
                </Card>
            </Fragment>
            </div>
        </>
    )
}
export default Chat;