import React from "react";
import "./Options.css";

const InitialOptions = (props) => {
  const initialOptionChoices = [
    {
      text: "Log Food",
      handler: props.actionProvider.chooseFood,
      id: 1
    },
    {
      text: "Give Feedback", 
      handler: props.actionProvider.sendFeedback
      ,id: 2 },
  ];

  const buttonsMarkup = initialOptionChoices.map((option) => (
    <button key={option.id} onClick={option.handler} className="option-button">
      {option.text}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default InitialOptions;
