import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { createUserProfile } from '../functions/FirebaseCalls';
import WebflowHeader from '../components/WebflowHeader';
import WebflowFooter from '../components/WebflowFooter';
import { Icon, InlineIcon } from '@iconify/react';
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Avatar,
    Input,
    Typography,
    Tabs,
    TabsHeader,
    TabPanel,
    TabsBody,
    Tab,
    Switch,
    Tooltip,
    Button,

} from "@material-tailwind/react";

const EmailVerification = () => {
    const [error, setError] = useState('')

    const {createResendEmail,resendEmail, createResendEmailLink} = UserAuth()
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        setError('')
        try{
            createResendEmail()
        } catch (e) {
            setError(e.message)
            console.log(e.message)
        }
    }

  return (
    <>
            <WebflowHeader />
            <div className='flex justify-center'>
                <Card className="w-10/12 lg:w-8/12 mb-6 border-solid border-2 p-2 border-gray-400 bg-teal-50">
                    <CardBody className="p-2">

                        <div className='flex flex-row justify-center mb-2 border-b-2 border-gray-500 rounded-b items-center'>
                            <img className='mb-2' src={require("../assets/images/avatar64x64.png")} />
                        </div>
                        <div className='flex flex-row justify-between '>
                            <h1 className="mb-4 text-lg font-bold leading-none text-gray-500 md:text-lg lg:text-5xl dark:text-white">Almost there...</h1>

                        </div>



                        <Typography
                            variant="med"
                            className="font-normal text-gray-600 leading-4"
                        >
                            We just need you to verify your email address.{<br/>}{<br/>}
                            If no email arrives in the next minute or two, check your spam or try resending via the button below.
                        </Typography>
                        <form className='mt-5 w-full lg:w-6/12'>
                            <Button onClick={handleSubmit} variant="gradient" color="teal" className='hover:bg-teal-500 hover:text-white w-full p-4 my-2 text-white'>Resend Verification Email</Button>
                        </form>
                       


                    </CardBody>




                
                   
                    <div className=' w-full'>
                        <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-1 shadow-md" role="alert">

                        <div className='text-sm' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <InlineIcon height="24" icon="mdi:information-outline" className='mr-3' />Please contact us if you have any trouble creating your account.
                        </div>
                        </div>

                    </div>
                </Card>

            </div>

            <WebflowFooter />
    </>
  )
}

export default EmailVerification