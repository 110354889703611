// AuthContext
import { createContext, useContext, useEffect, useState } from 'react';
import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    sendEmailVerification,
    onAuthStateChanged,
    updateProfile,
    generateEmailVerificationLink,
    sendPasswordResetEmail
} from 'firebase/auth'
import { auth } from '../firebase'
import { createUserProfile } from '../functions/FirebaseCalls';
import { Link, useNavigate } from 'react-router-dom'
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import axios from 'axios';


const UserContext = createContext();

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
    prompt: 'select_account'
});

const emailOptions = {
    from:'chris@friendlyfoodtracker.com',
    to:['chris@friendlyfoodtracker.com'],
    subject: '!!! New SignUp !!!',
    text: "Just letting you know you've got a new signup"
  };

var actionCodeSettings = {
    url: 'https://app.friendlyfoodtracker.com/chat',
    handleCodeInApp: true,
};

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const navigate = useNavigate()
    
    const createUser =  (email, password) => {
        return(
         createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                sendEmailVerification(auth.currentUser, actionCodeSettings)
                //console.log('Create User Msg:', userCredential)
                updateProfile(userCredential.user, { displayName: email })
                createUserProfile(email, userCredential.user.uid, email)
                navigate('/email-verification')
               // sendOnboardEmail(userCredential.user)
            })
           );
    };

    const signInWithGoogle = () => {
        return (
            signInWithPopup(auth, googleProvider).then((userCredential) => {
                createUserProfile(userCredential.user.email, userCredential.user.uid,  userCredential.user.displayName)
            })
        )
      };




const createResendEmail = () => {
    return (
        sendEmailVerification(auth.currentUser, actionCodeSettings)
    )
};


const createSendPasswordResetEmail = (email) => {
    return (
        sendPasswordResetEmail(auth, email).then(
            navigate('/password-reset-confirmation')
        )

    )
};

const logIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password)
}

const logOut = () => {
    return signOut(auth);
}

useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        //navigate('/dashboard')
    });
    return () => {
        unsubscribe();
    }
}, [])

// const sendOnboardEmail = async (user) => {
//     if (user.accessToken) {
//         const token = user.getIdToken()

//         const response = await trackPromise(axios.get(process.env.REACT_APP_API_URL + `/api/v1/send-onboard-email`, { headers: { "Authorization": `Bearer ${user.accessToken}` } }))
//         console.log('Onboard Email Sent')
//     }
//   }


return (
    <UserContext.Provider value={{ createUser, signInWithGoogle, createResendEmail, createSendPasswordResetEmail, user, logOut, logIn }}>
        {children}
    </UserContext.Provider>
);
}

export const UserAuth = () => {
    return useContext(UserContext)
}