//SignUp
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

import { createUserProfile } from '../functions/FirebaseCalls';
import { useForm } from 'react-hook-form'
import WebflowHeader from '../components/WebflowHeader';
import WebflowFooter from '../components/WebflowFooter';
import { Icon, InlineIcon } from '@iconify/react';
import {
    initAmplitude,
    sendAmplitudeData,
    setAmplitudeUserId
} from '../context/AnalyticsContext';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Avatar,
    Input,
    Typography,
    Tabs,
    TabsHeader,
    TabPanel,
    TabsBody,
    Tab,
    Switch,
    Tooltip,
    Button,

} from "@material-tailwind/react";

initAmplitude()

const PasswordReset = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)

    const { createSendPasswordResetEmail, user } = UserAuth()
    const navigate = useNavigate()




    const handleSubmit = async (e) => {
        e.preventDefault()
        //setError(await createUser(email, password))
        //sendAmplitudeData('auth.signUpButton.Clicked');
        try {
            await createSendPasswordResetEmail(email, password)
            //navigate('/chat')
        } catch (e) {
            setError(e.code)
            console.log(e.code)
        } finally {
            sendAmplitudeData('auth.logInEmailButton.Clicked')
        }

    }

    return (
        <>
            <WebflowHeader />
            <div className='flex justify-center'>
                <Card className="w-10/12 lg:w-8/12 mb-6 border-solid border-2 p-2 border-gray-400 bg-teal-50">
                    <CardBody className="p-2">

                        <div className='flex flex-row justify-center mb-2 border-b-2 border-gray-500 rounded-b items-center'>
                            <img className='mb-2' src={require("../assets/images/avatar64x64.png")} />
                        </div>
                        <div className='flex flex-row justify-between '>
                            <h1 className="mb-4 text-lg font-bold leading-none text-gray-500 md:text-lg lg:text-5xl dark:text-white">Password Reset</h1>

                        </div>



                        <Typography
                            variant="small"
                            className="font-normal text-gray-600"
                        >
                            Forgot your password? Enter your e-mail address below, and we'll send you an e-mail allowing you to reset it.
                        </Typography>






                        <div className='max-w-[600px] -mt-5 mx-left'>

                            <form>
                                <div className='w-full lg:w-6/12'>
                                    <div className='flex flex-col mb-2'>
                                        <Input color="teal" onChange={(e) => setEmail(e.target.value)} label="Email" />
                                    </div>
                                    
                                </div>
                               
                                <div className='w-full lg:w-6/12 mt-5'>

                                    <Button onClick={handleSubmit} variant="gradient" color="teal" className='hover:bg-teal-500 hover:text-white w-full text-white'>Send Password Reset</Button>

                                </div>

                            </form>

                        </div>
                        {
                            (error) ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 my-3 rounded relative" role="alert">
                            <strong className="font-bold">Oops: </strong>
                            <span className="block sm:inline">{error}</span>
                            
                          </div> : ""
                        }



                    </CardBody>

                    <CardFooter>
                         </CardFooter>


                        <p className='text-sm -mt-5 '>
                            Already have an account? <Link to='/' className='underline'>Sign in.</Link>

</p>
                   
                    <div className=' w-full'>
                        <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-1 shadow-md" role="alert">

                        <div className='text-sm' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <InlineIcon height="24" icon="mdi:information-outline" className='mr-3' />Please contact us if you have any trouble resetting your password.
                        </div>
                        </div>

                    </div>
                </Card>

            </div>
            <WebflowFooter />

        </>
    )
}

export default PasswordReset