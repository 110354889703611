// Profile
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useNavigate } from 'react-router-dom';
import { usePagination, useSortBy, useTable } from 'react-table';
import { UserAuth } from '../context/AuthContext';
import { Icon, InlineIcon } from '@iconify/react';
import DialogflowMessenger from '../components/DialogflowMessenger';



import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserId
} from '../context/AnalyticsContext';

import {
  Button, Card,
  CardBody, Input,
  Typography
} from "@material-tailwind/react";

initAmplitude()

const DeleteAccount = () => {
  const { promiseInProgress } = usePromiseTracker();
  const { user, logOut } = UserAuth();
  const [dailyTarget, setDailyTarget] = useState('')
  const [currentWeight, setCurrentWeight] = useState('')
  const [error, setError] = useState('')
  const [userProfile] = useState('')
  const [foodName, setFoodName] = useState('')
  const [confirmDelete, setConfirmDelete] = useState('')
  const [showTable, setShowTable] = useState('invisible')
  const { register, handleSubmit, reset } = useForm();
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const [lookupResultsData, setLookupResultsData] = useState([]);

  setAmplitudeUserId(user.uid);

  const handleDelete = async (e) => {
    try {
      e.preventDefault()
      deleteAccount()
     
    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.foodLookupButton.Clicked')
    }
  }

  const deleteAccount = async () => {
    if (user.accessToken) {
      const token = user.getIdToken()

      if (confirmDelete == 'delete') {
        const response = await trackPromise(axios.get(process.env.REACT_APP_API_URL + `/api/v1/delete-account`, { headers: { "Authorization": `Bearer ${user.accessToken}` } }))
        console.log('Delete Account')
        navigate('/')
      }
    }
  }

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/')
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.logOutButton.Clicked')
    }
  }

  const LoadingIndicator = props => {
    return (
      promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
      </div>
    );
  }

  return (
    <>
      <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url(https://images.unsplash.com/photo-1571074635691-b910c7a5cdbb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2103&q=80)] bg-cover bg-center">

      </div>
      <Card className="mx-3 -mt-16 mb-6 lg:mx-4">
        <CardBody className="p-4">
          <div className="flex items-center justify-between gap-6">
            <div className="flex items-center gap-6">
            <div>
              <Typography variant="h4" color="gray" className="mb-1">
              Delete Account
                </Typography>
              </div>
              
            </div>
          </div>
          {/* <Typography variant="h6" color="gray" >
                Settings
              </Typography> */}
         <div className='max-w-[600px] w-3/4 mx-left my-'>
            <form>
             
            <Typography
                variant="normal"
                className="font-normal text-gray-600 leading-tight"
              >
               Deleting your account is pretty straightforward. Simply type 'delete' in the box below and click the purple button.
              </Typography>
              <Typography
                variant="small"
                className="font-normal text-gray-600 mt-5 leading-tight"
              >
              Note that you'll lose access to any foods that you've previously logged.
              </Typography>    
              <label className='font-medium'></label>
              <div className='flex flex-col mt-5' >
                {/* <label className='py-2 font-medium'>Display Name:</label> */}
                <Input color="teal" onChange={(e) => setConfirmDelete(e.target.value)} label="Type 'delete' here" />
                <Button onClick={handleDelete} className='hover:opacity-80 bg-pinky hover:text-white w-full my-2 text-white'>Delete my Account</Button>
              </div>
              </form>

          </div>

          <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 mt-5 py-1 shadow-md" role="alert">

                <div className="font-bold" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <InlineIcon icon="mdi:information-outline" className='mr-3' />We need your feedback
              </div>
              <p className="text-sm">We're sorry you're leaving the site. Before you do we'd love some feedback on why you want to leave...</p>
              </div>


        </CardBody>
      </Card>

      <DialogflowMessenger/>
    </>
  )
}

export default DeleteAccount