// Firebase Calls
import { collection, addDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import TimezoneSelect, { allTimezones } from 'react-timezone-select'
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import axios from 'axios';


const createUserProfile = async (email, uid, displayName) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);
    
    if (!docSnap.exists()) {
        await setDoc(doc(db, "users", uid), {
            display_name: displayName,
            first_use: true,
            is_active: true,
            created_date: Date.now(),
            last_updated_date: Date.now(),
            account_type: 'appUser',
            send_weekly_digest: true,
            target_points: 5,
            current_weight: '',
            user_id: uid,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            whatsapp_id: '',
            whatsapp_id_verified: true,
        }).then(() => {
            sendOnboardEmail();
        }).catch((error) => {
            console.error("Error writing document: ", error);
        });
    }
};

const updateUserProfile = async (uid, displayName, targetPoints, currentWeight,timezone) => {
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        await updateDoc(doc(db, "users", uid), {
            display_name: displayName,
            last_updated_date: Date.now(),
            target_points: targetPoints,
            current_weight: currentWeight,
            timezone: timezone,
        });
    }
};

const addWeightValue = async (uid, currentWeight) => {

    await addDoc(collection(db, "user_weight_entries"), {
        user_id: uid,
        entry_date_unix: Math.floor((new Date()).getTime() / 1000),
        current_weight: currentWeight,
    });

    console.log("Adding Weight Value")

};

const displayUserSettings = async (uid) => {
        // TODO: This is getting called with an 'undefined' uid which is annoying!
        
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const profileVars = docSnap.data();
            console.log("Document data:", profileVars);
            return profileVars;
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
};


const sendOnboardEmail = async () => {
    console.log('Attempting to send onboard email:', auth.currentUser)
    if (auth.currentUser.accessToken) {
        const token = auth.currentUser.getIdToken()
        const response = await trackPromise(axios.get(process.env.REACT_APP_API_URL + `/api/v1/send-onboard-email`, { headers: { "Authorization": `Bearer ${auth.currentUser.accessToken}` } }))
        console.log('Onboard Email Sent')
    }
  }

export { createUserProfile, displayUserSettings, updateUserProfile, addWeightValue }