// Profile
import axios from 'axios';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UserAuth } from '../context/AuthContext';
import { Icon } from '@iconify/react';
import DialogflowMessenger from '../components/DialogflowMessenger';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { useCountries } from "use-react-countries";
import {
  addWeightValue
} from '../functions/FirebaseCalls';
import 'react-phone-input-2/lib/plain.css'
import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserId
} from '../context/AnalyticsContext';

import {
  Card,
  CardBody, Avatar,
  Input,
  Typography, Select,
  Option, Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter, Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

initAmplitude()

const Profile = () => {
  const { promiseInProgress } = usePromiseTracker();
  const { countries } = useCountries();
  const [country, setCountry] = React.useState(235);

  // Sort the countries array alphabetically by name
  const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));

  const { user, logOut } = UserAuth();
  const [dailyTarget, setDailyTarget] = useState('')
  const [currentWeight, setCurrentWeight] = useState('')
  const [timezone, setTimezone] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [countryCallingCode, setCountryCallingCode] = useState('')
  const [phoneNumberVerified, setPhoneNumberVerified] = useState(false)
  const [statusMessage, setStatusMessage] = useState('')
  const [errorStatus, setErrorStatus] = useState('')
  const [statusPhoneMessage, setStatusPhoneMessage] = useState('')
  const [errorPhoneStatus, setErrorPhoneStatus] = useState('')
  const [userProfile] = useState('')
  const [displayName, setDisplayName] = useState('')
  const { register, handleSubmit, reset } = useForm();
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const [size, setSize] = useState(null);

  const labelStyle = 'original'
  const timezones = {
    ...allTimezones
  }
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })

  const handleOpen = (value) => setSize(value);

  setAmplitudeUserId(user.uid);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/')
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.logOutButton.Clicked')
    }
  }

  const handleSave = async (e) => {
    try {
      e.preventDefault()
      setErrorStatus('')
      setStatusMessage('')
      setVerifyCode('')
      const isValid = validateEntries();

      if (validateEntries) {
        setUserProfileData();
      }

    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.saveProfileButton.Clicked')
    }
  }

  const handlePhoneSave = async (e) => {
    try {
      e.preventDefault()
      setErrorStatus('')
      setStatusMessage('')
      setErrorPhoneStatus('')
      setStatusPhoneMessage('')
      setVerifyCode('')
      const isValid = validatePhoneEntries();

      if (validatePhoneEntries) {
        setUserPhoneData();
      }

    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.savePhoneButton.Clicked')
    }
  }

  const validateEntries = () => {

    if (currentWeight !== '' && isNaN(currentWeight)) {
      setStatusMessage('')
      setErrorStatus('Current weight must be a valid number.')
      return false;
    } else if (dailyTarget !== '' && isNaN(dailyTarget)) {
      setStatusMessage('')
      setErrorStatus('Daily Target must be a valid number.')
      return false;
    } else if (displayName === "") {
      setStatusMessage('')
      setErrorStatus("Display Name can't be blank.")
      return false;
    }

    setErrorStatus('')
    return true;
  };



  const validatePhoneEntries = () => {

    if (phoneNumber !== '' && isNaN(phoneNumber)) {
      setStatusPhoneMessage('')
      setErrorPhoneStatus('Phone number must be a valid number.')
      return false;
    }

    setErrorPhoneStatus('')
    return true;
  };

  const LoadingIndicator = props => {
    return (
      promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
      </div>
    );
  }

  const setWeightValue = async () => {
    await addWeightValue(user.uid, currentWeight);
  }

  const setUserProfileData = async () => {

    try {
      const response = await trackPromise(axios.post(process.env.REACT_APP_API_URL + `/api/v1/update-profile-data`, {
        displayName,
        dailyTarget,
        currentWeight,
        timezone,
      },
        { headers: { "Authorization": `Bearer ${user.accessToken}` } }));

      setStatusMessage(response.data.success)
      setErrorStatus('')
      console.log(response.data)

    } catch (e) {
      setErrorStatus(e.response.data.error)
      console.log(e.response.data.error)
    }
  }

  const setUserPhoneData = async () => {

    try {
      const response = await trackPromise(axios.post(process.env.REACT_APP_API_URL + `/api/v1/update-profile-phone-data`, {
        countryCallingCode,
        phoneNumber
      },
        { headers: { "Authorization": `Bearer ${user.accessToken}` } }));

      setStatusPhoneMessage(response.data.success)
      setErrorPhoneStatus('')
      console.log(response.data)

    } catch (e) {
      setErrorPhoneStatus(e.response.data.error)
      console.log(e.response.data.error)
    }
  }

  const handlePhoneValidation = async () => {

    if (verifyCode === '') {
      setErrorPhoneStatus('Please enter a valid verification code.')
    }
    else {
      try {
        const response = await trackPromise(axios.post(process.env.REACT_APP_API_URL + `/api/v1/validate-phone`, {
          verifyCode
        },
          { headers: { "Authorization": `Bearer ${user.accessToken}` } }));

        setStatusPhoneMessage(response.data.success)
        setErrorPhoneStatus('')
        console.log(response.data)

      } catch (e) {
        setErrorPhoneStatus(e.response.data.error)
        console.log(e.response.data.error)
      }
    }
  }

  useEffect(() => {

    if (user.accessToken) {

      const token = user.getIdToken()
      const fetchProfileData = async () => {

        const axios_config = {
          method: 'GET',
          url: process.env.REACT_APP_API_URL + `/api/v1/get-profile-data`,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.accessToken}`
          },
          queryInput: {
            text: {
              timezoneOffset: new Date().getTimezoneOffset(),
            },
          },
        };

        const response = await trackPromise(axios(axios_config))

        const data = await response.data;

        console.log("Profile Data:", data)
        setDisplayName(data.display_name);
        setDailyTarget(data.target_points);
        setCurrentWeight(data.current_weight);
        setTimezone(data.timezone);
        setCountryCallingCode(data.country_code);
        if (countryCallingCode === '') {
          setCountryCallingCode('+44');
        }
        setPhoneNumber(data.phone_number);
        setPhoneNumberVerified(data.whatsapp_id_verified);
        console.log('Display Name: ', displayName)

      }
      // Fetch 'feed' data then aggregate it
      fetchProfileData();

    }
  }, [user]);

  useEffect(() => {
    // reset form with user data
    reset(profile);
  }, [profile]);


  return (
    <>


      <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url(https://images.unsplash.com/photo-1519733870-f96bef9bc85f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1769&q=80)] bg-cover	bg-center">

      </div>

      <Card className="mx-3 -mt-16 mb-6 lg:mx-4">

        <CardBody className="p-4">


          <div className="flex items-center justify-between gap-6">
            <div className="flex items-center gap-6">
              <div className='mb-5'>
                {/* <Avatar src={require('../assets/images/blank-profile.png')} alt="avatar" /> */}
                <Typography variant="h4" color="gray" className="mb-1">
                  Profile              
                  </Typography>
                <Typography
                  variant="medium"
                  className="font-normal text-secondary"
                >
                  Edit your profile here
                </Typography>
                <div>
                  <Typography variant="h5" color="gray">

                  </Typography>
                  <Typography
                    variant="small"
                    className="font-normal text-blue-gray-600"
                  >

                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className='max-w-[600px] w-3/4 mx-left'>
            <form>
              <label className='font-medium'></label>
              <div className='flex flex-col'>
              <Input 
                color="teal" 
                label='Display Name'
                value={displayName} 
                onChange={(e) => setDisplayName(e.target.value)} />
              </div>
              <div className='flex flex-row align-top  py-2 my-2'>
                {/* <Icon icon="material-symbols:info" width="48" color="#54b6bb" className='pr-2 pb-1 hover:teal-700' onClick={() => handleOpen("xl")} /> */}

                {/* <label className='py-2 font-medium'>Daily Points Target</label> */}
                <Input color="teal" value={dailyTarget} onChange={(e) => setDailyTarget(e.target.value)} label="Daily Points Target" />
                <Button className='content-begin' variant="text" size="sm" color="teal"><Icon icon="material-symbols:info" width="24" color="#54b6bb" onClick={() => handleOpen("xl")} /></Button>


                <Dialog open={
                  size === "xs" ||
                  size === "sm" ||
                  size === "md" ||
                  size === "lg" ||
                  size === "xl" ||
                  size === "xxl"
                }
                  size={size || "xl"}
                  handler={handleOpen}>
                  <DialogHeader>Setting Targets</DialogHeader>
                  <DialogBody divider>
                    Hold yourself accountable by setting some targets. There's no one-size-fits-all DQS target; incremental improvements are recommended.{<br />}{<br />}

                    However, for context, the maximum score you can achieve daily is 35 points and even elite endurance athletes are typically low-to-mid 20s.{<br />}{<br />}

                    The default target when you sign up is 5 points.
                  </DialogBody>
                  <DialogFooter>
                    <Button variant="gradient" color="teal" onClick={handleOpen}>
                      <span>Close</span>
                    </Button>
                  </DialogFooter>
                </Dialog>
              </div>
              <div className='flex flex-col py-2'>
                {/* <label className='py-2 font-medium'>Current Weight (kg)</label> */}
                <Input color="teal" className='active:border-b-indigo-500' value={currentWeight} onChange={(e) => setCurrentWeight(e.target.value)} label="Current Weight (kg)" />
              </div>
              <div className='flex flex-col py-2'>
                <Select
                  variant="outline"
                  color="teal"
                  label="Select Timezone"
                  value={timezone}
                  {...register('timezone')}
                  onChange={(e) => setTimezone(e)}
                >
                  {options.map(option => (
                    <Option key={option.value} value={option.value}>
                      &ensp;&ensp;{option.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className='flex flex-col '>
                <Button onClick={handleSave} className='bg-primary font-color:teal hover:opacity-80  w-full my-2 text-light'>Save</Button>

                <Button onClick={handleLogout} className='bg-light border-solid border-2 border-primary hover:opacity-80 w-full my-2 text-teal'>Log Out</Button>
              </div>

              <div className='flex flex-col'>
                {
                  (statusMessage === '') ? "" :
                    <div className="text-sm bg-teal-100 border border-teal-400 mt-4 rounded px-4 shadow-md" role="alert">
                      {statusMessage}</div>
                }
              </div>
              <div className='flex flex-col'>
                {
                  (errorStatus === '') ? "" :
                    <div className="text-sm bg-red-100 border border-red-400 mt-4 rounded px-4 shadow-md" role="alert">
                      {errorStatus}</div>
                }
              </div>
              <div className='flex flex-col pt-2'>
                <Typography
                  variant="small"
                  className="font-normal text-gray-600"
                >
                  If you're looking to delete your account, click <a href='/delete-account'>here</a>
                </Typography>
              </div>

            </form>
          </div>


        </CardBody>
      </Card >



      <DialogflowMessenger />
    </>
  )
}

export default Profile