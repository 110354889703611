// Profile
import axios from 'axios';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { ThreeDots } from 'react-loader-spinner';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { UserAuth } from '../context/AuthContext';
import { Icon } from '@iconify/react';
import DialogflowMessenger from '../components/DialogflowMessenger';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { useCountries } from "use-react-countries";
import {
  addWeightValue
} from '../functions/FirebaseCalls';
import 'react-phone-input-2/lib/plain.css'
import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserId
} from '../context/AnalyticsContext';

import {
  Alert,
  Card,
  CardBody, Avatar,
  Input,
  Typography, Select,
  Option, Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter, Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Switch,
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

import Stepper from "awesome-react-stepper";

initAmplitude()

const WhatsApp = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(1);
  const [isLastStep, setIsLastStep] = React.useState(false);
  const [isFirstStep, setIsFirstStep] = React.useState(false);

  const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  const { promiseInProgress } = usePromiseTracker();
  const { countries } = useCountries();
  const [country, setCountry] = React.useState(235);

  // Sort the countries array alphabetically by name
  const sortedCountries = countries.sort((a, b) => a.name.localeCompare(b.name));
  const { user, logOut } = UserAuth();
  const [origPhoneNumber, setOrigPhoneNumber] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [verifyCode, setVerifyCode] = useState('')
  const [countryCallingCode, setCountryCallingCode] = useState('')
  const labelStyle = 'original'

  // Whatsapp stuff
  const [whatsAppDetails, setWhatsAppDetails] = useState(null);
  const [isWhatsAppActive, setIsWhatsAppActive] = useState(false)
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false)
  const [isPhoneNumberVerified, setIsPhoneNumberVerified] = useState(false)

  const timezones = {
    ...allTimezones
  }
  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })

  setAmplitudeUserId(user.uid);

  const handleNextPress = (step) => {
    if (step.step === 2) {
      if (origPhoneNumber != phoneNumber){
        setIsPhoneNumberValid(validatePhoneNumber());
        sendVerificationCode();
      }
    } else if (step.step === 3) {
      if (phoneNumber === '' & isWhatsAppActive === true) {
        deactivateWhatsApp();
      }
      else {
        setIsPhoneNumberVerified(verifyPhoneNumber());
      }

    } else {
      return false;
    }
  }

  const deactivateWhatsApp = async () => {

    try {
      const response = await trackPromise(axios.post(process.env.REACT_APP_API_URL + `/api/v1/deactivate-whatsapp`, {
      },
        { headers: { "Authorization": `Bearer ${user.accessToken}` } }));

      // setStatusPhoneMessage(response.data.success)
      // setErrorPhoneStatus('')
      // console.log(response.data)

    } catch (e) {
      // setErrorPhoneStatus(e.response.data.error)
      // console.log(e.response.data.error)
    }
  }

  const validatePhoneNumber = () => {

    if (phoneNumber !== '' && isNaN(phoneNumber)) {
      // setStatusPhoneMessage('')
      // setErrorPhoneStatus('Phone number must be a valid number.')
      return false;
    }
    //setErrorPhoneStatus('')
    return true;
  };

  const sendVerificationCode = async () => {

    try {
      const response = await trackPromise(axios.post(process.env.REACT_APP_API_URL + `/api/v1/send-verification-code`, {
        countryCallingCode,
        phoneNumber
      },
        { headers: { "Authorization": `Bearer ${user.accessToken}` } }));

      // setStatusPhoneMessage(response.data.success)
      // setErrorPhoneStatus('')
      // console.log(response.data)

    } catch (e) {
      // setErrorPhoneStatus(e.response.data.error)
      // console.log(e.response.data.error)
    }
  }

  const verifyPhoneNumber = async () => {

    if (verifyCode === '') {
      // setErrorPhoneStatus('Please enter a valid verification code.')
    }
    else {
      try {
        const response = await trackPromise(axios.post(process.env.REACT_APP_API_URL + `/api/v1/verify-phone-number`, {
          verifyCode
        },
          { headers: { "Authorization": `Bearer ${user.accessToken}` } }));

        //setStatusPhoneMessage(response.data.success)
        //setErrorPhoneStatus('')
        console.log(response.data)
        return true;

      } catch (e) {
        // setErrorPhoneStatus(e.response.data.error)
        console.log(e.response.data.error)
        return false;
      }
    }
  }

  const LoadingIndicator = props => {
    return (
      promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
      </div>
    );
  }



  useEffect(() => {

    if (user.accessToken) {
      const token = user.getIdToken()
      const fetchWhatsAppData = async () => {

        const axios_config = {
          method: 'GET',
          url: process.env.REACT_APP_API_URL + `/api/v1/get-whatsapp-data`,
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.accessToken}`
          },
          queryInput: {
            text: {
              timezoneOffset: new Date().getTimezoneOffset(),
            },
          },
        };

        const response = await trackPromise(axios(axios_config))

        const data = await response.data;

        console.log("Feetching WhatsApp Data:", data)
        setCountryCallingCode(data.country_code);
        if (countryCallingCode === '') {
          setCountryCallingCode('+44');
        }
        setOrigPhoneNumber(data.phone_number);
        console.log("Orig Phone Number:", data.phone_number)
        setPhoneNumber(data.phone_number);
        console.log("Phone Number:", data.phone_number)
        setIsWhatsAppActive(data.whatsapp_active);
        console.log("Is WhatsApp Active:", data.whatsapp_active)

      }
      fetchWhatsAppData();
    }
  }, [user]);

  // useEffect(() => {
  //   // reset form with user data
  //   reset(profile);
  // }, [profile]);


  return (
    <>
      <div className="relative bg-light mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url(https://images.unsplash.com/photo-1579254646874-46ce7049df38?q=80&w=870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)] bg-cover	bg-center">

      </div>

      <Card className="mx-3 -mt-16 mb-6 lg:mx-4 bg-light ">

        <CardBody className="p-4">
          <div className="flex items-center justify-between gap-6">
            <div className="flex items-center gap-6">
              <div className='mb-5'>
                <Typography variant="h4" color="gray" className="mb-1">
                  WhatsApp Settings
                </Typography>
                <Typography
                  variant="medium"
                  className="font-normal text-secondary"
                >
                  Set up your WhatsApp integration here
                </Typography>
              </div>
            </div>
          </div>

          <div className='max-w-[600px] w-full mx-left'>
            <div>
              <Stepper
                strokeColor="#54b6bb"
                fillStroke="#54b6bb"
                activeProgressBorder="2px solid #54b6bb"
                activeColor="#54b6bb"
                continueBtn={<Button className="bg-primary">Next</Button>}
                onContinue={
                  (step) => handleNextPress({ step })
                }
                backBtn={<Button className="bg-primary">Back</Button>}
                submitBtn={<Button className="bg-primary">Close</Button>}
                onSubmit={(step) => navigate('/chat')}
              >
                <div className='container bg-light mx-auto mt-5'>
                  {
                    (promiseInProgress === true) ? <LoadingIndicator /> :
                      (isWhatsAppActive) ?
                      
                        <div id='alreadyVerified'>
                          <Typography variant="h6" color="gray" className="mb-1">
                           You're already set up for WhatsApp
                          </Typography>
                          <Typography
                            variant="small"
                            className="font-normal text-secondary"
                          >
                            You can change your number here or set it to blank to deactivate WhatsApp
                          </Typography>
                         
                        </div>
                        : 
                        
                        <div id='notVerified'>
                          <h1>Tell us your phone number - we'll send you a verification code to your SMS</h1>
                        </div>
                  }
                  <form>
                    <div className='flex flex-row align-top my-2'>

                      <Menu placement="bottom-start">
                        <MenuHandler>
                          <Button
                            ripple={false}
                            variant="text"
                            color="blue-gray"
                            className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
                          >
                            {countryCallingCode}
                          </Button>
                        </MenuHandler>
                        <MenuList className="max-h-[20rem] max-w-[18rem]">
                          {countries.map(
                            ({ name, countryCallingCode }, index) => {
                              return (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  className="flex items-center gap-2"
                                  onClick={() => setCountryCallingCode(countryCallingCode)}
                                >
                                  {name} <span className="ml-auto">{countryCallingCode}</span>
                                </MenuItem>
                              );
                            }
                          )}
                        </MenuList>
                      </Menu>
                      <Input
                        type="tel"
                        color='teal'
                        value={phoneNumber}
                        placeholder="Type your WhatsApp number here. Click 'Next' to verify it."
                        className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-teal-500 pr-10"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        labelProps={{
                          className: "before:content-none after:content-none",
                        }}
                      />

                    </div>
                  </form>
                </div>

                <div className='container bg-light mx-auto mt-5'>
                  {
                    (promiseInProgress === true) ? <LoadingIndicator /> : 
                    (origPhoneNumber === phoneNumber & isWhatsAppActive === true)  ?
                      <div id='noPhoneNumberChange'>
                         <Typography
                            variant="small"
                            className="font-normal text-secondary"
                          > 
                         It appears you haven't changed your phone number. <br/>If that's correct, feel free to click 'Next' or choose another option from the menu.
                        </Typography>
                      </div>
                      : 
                    !((isPhoneNumberValid)) ?
                    <div id='phoneNumberValidFalse'>
                      <p>Click on the 'Back' button and fix the issue to continue</p>
                      </div> :
                      ((phoneNumber == '')) ?
                      <div id='phoneNumberEmpty'>
                          <Typography
                            variant="small"
                            className="font-normal text-secondary"
                          > 
                            The phone number you entered is empty. Clicking 'Next' will deactivate your WhatsApp integration.
                          </Typography>
                      </div> : 
                      <div id='phoneNumberValid'>
                         <Typography
                            variant="small"
                            className="font-normal text-secondary"
                          >
                            You should have received a verification code. Enter it here...
                          </Typography>
                  
                      <div className='flex flex-row my-2'>
                        <Input maxLength="6" label='test' color="teal" value={verifyCode} onChange={(e) => setVerifyCode(e.target.value)} label="Type Verification Code" />
                      </div>
                    </div>
                  }
                </div>
                {
                  (promiseInProgress === true) ? <LoadingIndicator /> :
                    (phoneNumber === '') ? 
                    <div id='whatsAppDeactivated'>
                        <div className='container bg-light mx-auto mt-5'>
                          <h1>You've now successfully deactivated Whats App from your account.</h1>
                          <div className='flex flex-row my-2'>
                          </div>
                        </div>
                      </div>
                      :
                    (isPhoneNumberVerified) ?
                      <div id='phoneNumberVerified'>
                        <div className='container bg-light mx-auto mt-5'>
                          <h1>You've now successfully set up WhatsApp for Friendly Food Tracker!</h1>
                          <div className='flex flex-row my-2'>
                          </div>
                        </div>
                      </div> :
                      <div id='phoneNumberVerifiedFalse'>
                        <h1></h1>
                        <p>Click on the 'Back' and fix the issue to continue</p>
                      </div>
                }
              </Stepper>
            </div>
          </div>
        </CardBody>
      </Card >
      <DialogflowMessenger />
    </>
  )
}

export default WhatsApp