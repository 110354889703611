//Login
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import {
    initAmplitude,
    sendAmplitudeData
} from '../context/AnalyticsContext';
import WebflowHeader from '../components/WebflowHeader';
import WebflowFooter from '../components/WebflowFooter';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Avatar,
    Input,
    Typography,
    Tabs,
    TabsHeader,
    TabPanel,
    TabsBody,
    Tab,
    Switch,
    Tooltip,
    Button,

} from "@material-tailwind/react";
import { Icon, InlineIcon } from '@iconify/react';

initAmplitude()


const LogIn = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)
    const navigate = useNavigate();

    const { signInWithGoogle, logIn } = UserAuth();

    const handleGoogleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithGoogle()
            navigate('/chat')
        } catch (e) {
            setError(e.code)
            console.log(e.code)
        } finally {
            sendAmplitudeData('auth.logInWithGoogleButton.Clicked')
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await logIn(email, password)
            navigate('/chat')
        } catch (e) {
            setError(e.code)
            console.log(e.code)
        } finally {
            sendAmplitudeData('auth.logInEmailButton.Clicked')
        }
    }

    return (
        <>
            <WebflowHeader />
            <div className='flex justify-center my-10'>
                <Card className="w-10/12 lg:w-6/12 mb-6 border-solid border-2 p-2 border-gray-400 bg-teal-50">
                    <CardBody className="p-2">

                        <div className='flex flex-row justify-center mb-2 border-b-2 border-gray-500 rounded-b items-center'>
                            <img className='mb-2' src={require("../assets/images/avatar64x64.png")} />
                        </div>
                        <div className='flex flex-row justify-between'>
                            <h1 className="mb-4 text-lg font-bold leading-none text-gray-500 md:text-lg lg:text-5xl dark:text-white">Log in</h1>

                        </div>

                        <div className='mx-left'>

                            <form>
                                <div className='w-full lg:w-12/12'>
                                    <div className='flex flex-col mb-2'>
                                        <Input color="teal" onChange={(e) => setEmail(e.target.value)} label="Email" />
                                    </div>
                                    <div className='flex flex-col py-2 pb-5'>
                                        <Input color="teal" onChange={(e) => setPassword(e.target.value)} label="Password" type="password" />
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <p className='text-sm mb-2' >
                                        <Link to='/password-reset' className='underline'>Forgot your password?</Link>
                                    </p>
                                    <p className='text-sm'>
                                        Don't have an account yet? <Link to='/signup' className='underline'>Sign up.</Link>
                                    </p>
                                </div>

                                <div className='w-full lg:w-6/12  mb-2'>
                                    <Button onClick={handleSubmit}  className='bg-primary hover:opacity-80 w-full text-white'>Log in</Button>

                                </div>

                                <div className='w-full lg:w-6/12'>

                                    <Button onClick={handleGoogleSubmit} variant="gradient" color="blue" className='hover:bg-blue-500 hover:text-white w-full text-white'>Login with Google </Button>

                                </div>



                            </form>

                        </div>
                        {
                            (error) ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 my-3 rounded relative" role="alert">
                                <strong className="font-bold">Oops: </strong>
                                <span className="block sm:inline">{error}</span>

                            </div> : ""
                        }



                    </CardBody>

                    

                    
                </Card>

            </div>

        <WebflowFooter />
    </>
    )
}

export default LogIn