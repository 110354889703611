import SummaryPointsSingleStat from '../components/charts/SummaryPointsSingleStat';
import DailyPointsChart from '../components/charts/DailyPointsChart';
import WeeklyPointsChart from '../components/charts/WeeklyPointsChart';
import MealTypePointsChart from '../components/charts/MealTypePointsChart';
import FoodEntryTable from '../components/FoodEntryTable';
import DialogflowMessenger from '../components/DialogflowMessenger';

const Dashboard = () => {
    return (
        <>
            <div className="bg-secondary px-3 md:px-8 h-40" />
            <div className="px-3 md:px-8 -mt-36">
                <SummaryPointsSingleStat />

            </div>

            <div className="px-3 md:px-8">
                <div className="container mx-auto max-w-full">
                    <div className="grid grid-cols-1 xl:grid-cols-4">
                        <div className="xl:col-start-1 xl:col-end-4 px-4 mb-14">
                            <DailyPointsChart />
                        </div>
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-10">
                        <div className="xl:col-start-1 xl:col-end-5 px-4 mb-14">
                            <WeeklyPointsChart />
                        </div>
                        <div className="xl:col-start-6 xl:col-end-10 px-4 mb-14">
                            <MealTypePointsChart />
                        </div>
                        {/* <div className="xl:col-start-3 xl:col-end-9 px-4 mb-14">
                            
                        </div> */}
                    </div>
                </div>
            </div>

            <FoodEntryTable />

            <DialogflowMessenger />
        </>
    )
}

export default Dashboard