import React, { useState } from 'react';
import { createChatBotMessage, createClientMessage } from 'react-chatbot-kit';
import ActionProvider from './chatbot/ActionProvider.jsx';
import MessageParser from './chatbot/MessageParser.jsx';
import config from './chatbot/config';
import Chatbot from 'react-chatbot-kit';
import { InlineIcon } from '@iconify/react';
import { UserAuth } from './../context/AuthContext';

import 'react-chatbot-kit/build/main.css';
import './chatbot/Chatbot.css';

const ChatBot = ({ initialMessage, displayName }) => {
  const [state, setState] = useState({ messages: [] });
  const [messages, setMessages] = useState([]);
  const { user } = UserAuth();
  const authToken = 'test';

  
  return (
    <div className='w-12/12 lg:w-8/12'>
     
      {/* <div className="bg-orange-100 border-t-4 border-orange-500 rounded-b text-orange-900 px-4 mt-5 shadow-md" role="alert">

        <div className="font-bold text-sm" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <InlineIcon icon="mdi:information-outline" className='mr-3' />This page is slower than we'd like. 
        </div>
        <p className="text-sm"> We're working on it but should mention that we also integrate with WhatsApp!</p>
      </div> */}

      <Chatbot
        config={{
          ...config,
          initialMessages: [
            createChatBotMessage(`Hi ${displayName}, how can I help you today?`),
            createChatBotMessage(
              initialMessage.split('\n').map((text, index) => (
                <React.Fragment key={index}>
                  {text}
                  <br />
                </React.Fragment>
              )), {
              withAvatar: true,
              widget: 'initialOptions',
            }),
          ],
        }}
        actionProvider={ActionProvider}
        messageParser={MessageParser}
      />
    </div>
  );
};

export default ChatBot;
