//SignUp
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

import { createUserProfile } from '../functions/FirebaseCalls';
import { useForm } from 'react-hook-form'
import WebflowHeader from '../components/WebflowHeader';
import WebflowFooter from '../components/WebflowFooter';
import { Icon, InlineIcon } from '@iconify/react';
import {
    initAmplitude,
    sendAmplitudeData,
    setAmplitudeUserId
} from '../context/AnalyticsContext';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Avatar,
    Input,
    Typography,
    Tabs,
    TabsHeader,
    TabPanel,
    TabsBody,
    Tab,
    Switch,
    Tooltip,
    Button,

} from "@material-tailwind/react";

initAmplitude()

const PasswordResetConfirmation = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)

    const { createSendPasswordResetEmail, user } = UserAuth()
    const navigate = useNavigate()




    const handleSubmit = async (e) => {
        e.preventDefault()
        //setError(await createUser(email, password))
        //sendAmplitudeData('auth.signUpButton.Clicked');
        try {
            await createSendPasswordResetEmail(email, password)
            //navigate('/chat')
        } catch (e) {
            setError(e.code)
            console.log(e.code)
        } finally {
            sendAmplitudeData('auth.logInEmailButton.Clicked')
        }

    }

    return (
        <>
            <WebflowHeader />
            <div className='flex justify-center'>
                <Card className="w-10/12 lg:w-8/12 mb-6 border-solid border-2 p-2 border-gray-400 bg-teal-50">
                    <CardBody className="p-2">

                        <div className='flex flex-row justify-center mb-2 border-b-2 border-gray-500 rounded-b items-center'>
                            <img className='mb-2' src={require("../assets/images/avatar64x64.png")} />
                        </div>
                        <div className='flex flex-row justify-between '>
                            <h1 className="mb-4 text-lg font-bold leading-none text-gray-500 md:text-lg lg:text-5xl dark:text-white">Check Your Mail</h1>

                        </div>



                        <Typography
                            variant="med"
                            className="font-normal text-gray-600 leading-4"
                        >
                            We've sent a password reset instruction email to your mail.{<br/>}{<br/>}
                            Didn't receive the email?  Check your spam filter or <Link to='/password-reset' className='underline'>try another email address</Link>
                        </Typography>
                       


                    </CardBody>




                
                   
                    <div className=' w-full'>
                        <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-1 shadow-md" role="alert">

                        <div className='text-sm' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <InlineIcon height="24" icon="mdi:information-outline" className='mr-3' />Please contact us if you have any trouble resetting your password.
                        </div>
                        </div>

                    </div>
                </Card>

            </div>
            <WebflowFooter />

        </>
    )
}

export default PasswordResetConfirmation