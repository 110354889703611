
// import { Card, CardActions, CardHeader, CardMedia, CardContent } from '@mui/material';
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardStatus,
    Typography,
} from "@material-tailwind/react";
import { Icon, InlineIcon } from '@iconify/react';



export default function StatusCard({
    color,
    icon,
    title,
    amount,
    percentage,
    percentageColor,
    percentageIcon,
    date,
}) {
    return (
        <div className="px-4 mb-10 ">
            <Card>
                <CardHeader variant="gradient" color={color} className='w-20'>
                    <div className="flex h-20 items-center justify-center">
                        <Icon className='mx-auto' icon={icon} width="36" color="white" />
                    </div>
                </CardHeader>
                <CardBody className='h-16' >
                    <div className='flex flex-row justify-end'>
                        <div className='-mt-10'>
                            <Typography>
                                {title}
                                <Typography className='text-right' variant='h4'>
                                {amount}
                                </Typography>
                            </Typography>
                        </div>

                    </div>

                </CardBody>
                {/* <CardFooter divider className="flex items-top justify-between h-5">
                    <Typography variant="small" className="flex justify-center">
                        +55%
                        <Typography variant="small"
                            color="blue"
                            className="ml-1 font-bold">
                            than last week
                        </Typography>
                    </Typography>

                </CardFooter> */}
            </Card>
            {/* <Card>
                <CardHeader
                    variant="gradient"
                    color={color}
                    className="mb-2 grid h-14 place-items-left"
                >
                    <div className='px-4' style={{ display: 'flex', flexDirection: 'row', alignItems: 'items-start' }}>
                        <InlineIcon icon={icon} width="36" color="white" />

                    </div>


                </CardHeader>

                <CardBody >
                    <Typography variant="h5" className="mb-2 float-right">

                    </Typography>
                    <Typography className="mb-2 float-right">
                        {amount}
                    </Typography>
                </CardBody> */}

                {/* Card Footer *will* show day on day / week on week variance */}
                {/* <CardFooter divider className="flex items-center justify-between h-5">
                <Typography variant="small" className="flex justify-center">
                        {percentage}

                        <Typography variant="small"
                            color="blue"
                            className="ml-1 font-bold">
                            {date}
                        </Typography>
                    </Typography>

                </CardFooter> 

            </Card>*/}


        </div>
    );
}
