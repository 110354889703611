import React, { Fragment, useEffect, useState } from 'react';
import { UserAuth } from '../context/AuthContext';

export default function DialogflowMessenger() {
    const { user, logOut } = UserAuth();
    var offset = 'tzOffsetMins=' + new Date().getTimezoneOffset();
    var source = 'source=df-messenger';
    var sessionParams = offset + '&' + source;

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1";
        script.async = true;
        document.head.appendChild(script);
    
        return () => {
            document.head.removeChild(script);
          };

    }, []);


    return (
        <>
        <div>
     
                <df-messenger
                    chat-icon="https://storage.googleapis.com/lhotse-server.appspot.com/male_green_64x64.png"
                    intent="df-welcome"
                    chat-title="Hi, how can I help?"
                    agent-id="01d13631-b46a-4925-b6cc-138812fe3a47"
                    user-id={user.uid}
                    session-id={sessionParams}
                    language-code="en"
                    wait-open="true"
                >
                    
                </df-messenger>
            </div>

            <style>
                {
                    `df-messenger {
                        --df-messenger-bot-message: #878fac;
                        --df-messenger-button-titlebar-color: #2e3064;
                        --df-messenger-chat-background-color: #fafafa;
                        --df-messenger-font-color: #fafafa;
                        --df-messenger-bot-message-color: #fafafa;
                        --df-messenger-send-icon: #878fac;
                        --df-messenger-user-message: #54b6bb;
                        --df-messenger-chip-border-color:#54b6bb;
                        --df-messenger-chip-color: #54b6bb;
                        --df-messenger-chip-font-color: #fafafa;
                    }
                    `
                }

            </style>
            </>
    );
}