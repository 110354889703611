//SignUp
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

import { createUserProfile } from '../functions/FirebaseCalls';
import { useForm } from 'react-hook-form'
import WebflowHeader from '../components/WebflowHeader';
import WebflowFooter from '../components/WebflowFooter';
import { Icon, InlineIcon } from '@iconify/react';
import {
    initAmplitude,
    sendAmplitudeData,
    setAmplitudeUserId
} from '../context/AnalyticsContext';
import {
    Alert,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Avatar,
    Input,
    Typography,
    Tabs,
    TabsHeader,
    TabPanel,
    TabsBody,
    Tab,
    Switch,
    Tooltip,
    Button,

} from "@material-tailwind/react";

initAmplitude()

const SignUp = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState(null)

    const { createUser, user } = UserAuth()
    const navigate = useNavigate()
    const { signInWithGoogle, logIn } = UserAuth();

    const handleGoogleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithGoogle()
            navigate('/chat')
        } catch (e) {
            setError(e.code)
            console.log(e.code)
        } finally {
            sendAmplitudeData('auth.logInWithGoogleButton.Clicked')
        }
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        //setError(await createUser(email, password))
        //sendAmplitudeData('auth.signUpButton.Clicked');
        try {
            await createUser(email, password)
            //navigate('/chat')
        } catch (e) {
            setError(e.code)
            console.log(e.code)
        } finally {
            sendAmplitudeData('auth.logInEmailButton.Clicked')
        }

    }

    return (
        <>
            <WebflowHeader />
            <div className='flex justify-center my-10'>
                <Card className="w-10/12 lg:w-6/12 border-solid border-2 p-2 border-gray-400 bg-teal-50">
                    <CardBody className="p-2">

                        <div className='flex flex-row justify-center mb-2 border-b-2 border-gray-500 rounded-b items-center'>
                            <img className='mb-2' src={require("../assets/images/avatar64x64.png")} />
                        </div>
                        <div className='flex flex-row justify-between '>
                            <h1 className="mb-4 text-lg font-bold leading-none text-gray-500 md:text-lg lg:text-5xl dark:text-white">Create your <span className='text-primary'>free</span> account</h1>
                        </div>

                        <div className='mx-left'>
                            <form>
                                <div className='w-full lg:w-12/12'>
                                    <div className='flex flex-col mb-2'>
                                        <Input color="teal" onChange={(e) => setEmail(e.target.value)} label="Email" />
                                    </div>
                                    <div className='flex flex-col py-2 pb-5'>
                                        <Input color="teal" onChange={(e) => setPassword(e.target.value)} label="Password" type="password" />
                                    </div>
                                </div>
                                <div className='mb-2'>
                                    <p className='text-xs'>By creating an account, you agree to our <a href='https://www.friendlyfoodtracker.com/terms-of-service' style={{ textDecoration: 'underline' }}>Terms of Service</a> and <a href='https://www.friendlyfoodtracker.com/privacy' style={{ textDecoration: 'underline' }}>Privacy Policy.</a><br /><br />
                                        We will occassionally send you emails to help you get the most out of Friendly Food Tracker products and services. You can unsubscribe at any time.</p>

                                </div>
                                <div className='w-full lg:w-6/12 mb-2'>

                                    <Button onClick={handleSubmit} className='bg-primary hover:opacity-80 w-full text-white'>Sign Up</Button>

                                </div>

                                <div className='w-full lg:w-6/12'>

                                    <Button onClick={handleGoogleSubmit} variant="gradient" color="blue" className='hover:bg-blue-500 hover:text-white w-full text-white'>Login with Google </Button>

                                </div>
                            </form>
                        

                        </div>
                        {
                            (error) ? <div className="bg-red-100 border border-red-400 text-red-700 px-4 my-3 rounded relative" role="alert">
                                <strong className="font-bold">Oops: </strong>
                                <span className="block sm:inline">{error}</span>

                            </div> : ""
                        }



                    </CardBody>

                    <CardFooter>
                        <p className='text-sm -mt-5'>
                            Already have an account? <Link to='/' className='underline'>Sign in.</Link>
                        </p>


                    </CardFooter>

                    <div className='flex flex-row w-auto'>
                        <div className="bg-primary border-t-4 border-pinky rounded-b text-light px-4 py-1 shadow-md" role="alert">
                            <div className="font-bold" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <InlineIcon height="24" icon="mdi:information-outline" className='mr-3' />Is this version for you?
                            </div>
                            <p className="text-sm mb-2">This is a super-early release of Friendly Food Tracker which we know is far from perfect yet might be moderately useful for some folks.
                                <br /><br />
                                Part of the deal with releasing early is that we'd love your feedback to help us build a better a product, so we'd love for you to have a play and let us know what you think.
                                <br /><br />
                                With that in mind, keep in mind the following:</p>
                            <ul className="text-sm pl-5">
                            
                                <li className="mb-2 text-sm list-disc">
                                    There will be food missing in our database - we're trying something different but we're committed to getting it right
                                </li>
                                <li className="mb-2 text-sm list-disc">
                                    There's probably bugs. Let us know and we'll get them fixed
                                </li>
                                <li className="text-sm list-disc">
                                    If you're aware of Matt Fitzgerald's DQS method of diet scoring, this will probably be easier for you to use. If you've never heard of it, let us know how we can do better to raise awareness
                                </li>
                            </ul>

                        </div>

                    </div>
                </Card>

            </div>
            <WebflowFooter />

        </>
    )
}

export default SignUp