import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { ThreeDots } from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { Icon } from '@iconify/react';
import {
    Card,
    CardHeader,
    CardBody, Typography,
    Button, Popover,
    PopoverHandler,
    PopoverContent
} from "@material-tailwind/react";
import GaugeChart from 'react-gauge-chart';


export default function DailyPointsChart() {
    const { promiseInProgress } = usePromiseTracker();
    const [pointsChartData, setPointsChartData] = useState([]);
    const [pointsPercentage, setPointsPercentage] = useState(0);
    const [pointsDelta, setPointsDelta] = useState(0);

    const { user } = UserAuth();

    useEffect(() => {
        if (user.accessToken) {
            const token = user.getIdToken()
            const fetchRawData = async () => {

                const axios_config = {
                    method: 'GET',
                    url: process.env.REACT_APP_API_URL + `/api/v1/daily-points-chart`,
                    headers: {
                        "Authorization": `Bearer ${user.accessToken}`
                    },
                    params: {
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                };

                const response = await trackPromise(axios(axios_config))
                const data = await response.data;

                console.log("Daily Points Chart:", data)

                fetchPointsChartData(data);
                setPointsPercentage(data[0].points_percentage);
                setPointsDelta(data[0].points_delta);

            }
            // Fetch 'feed' data then aggregate it
            fetchRawData();
        }
    }, [user]);

    const fetchPointsChartData = (data) => {
        if (data.length > 0) {
            setPointsChartData(data.sort((a, b) => (a.unix_value > b.unix_value) ? 1 : -1));
        }
    }

    const LoadingIndicator = props => {
        return (
            promiseInProgress &&
            <div
                style={{
                    width: "100%",
                    height: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
            </div>
        );
    };

    return (
        <>
            <div>
                <Card>
                    <CardHeader className="bg-primary text-left text-light p-4">
                        <Typography variant="h4">
                            Today's Progress
                            <Popover placement="bottom-start">
                                <PopoverHandler>
                                    <Button size="sm" variant="text" color="white" className='align-sub' >
                                        <Icon icon="material-symbols:info" width="24" color="white" />
                                    </Button>
                                </PopoverHandler>
                                <PopoverContent className='border-2 border-primary'>
                                    <Typography variant="h5" divider>Today so far</Typography>
                                    Shows your current status towards today's points target.{<br />}{<br />}Keep going! You can do it!
                                </PopoverContent>
                            </Popover>
                        </Typography>

                    </CardHeader>
                    <CardBody>
                        {
                            (promiseInProgress === true) ? <LoadingIndicator /> : <GaugeChart id="gauge-chart3"
                                colors={['#e564ac', '#dfb552', '#54b6bb']}
                                // hideText={true}
                                textColor={'#54b6bb'}
                                fontSize={'20'}
                                arcWidth={0.3}
                                percent={pointsPercentage}
                                needleColor="#73737a"
                                needleBaseColor="#73737a"
                                formatTextValue={value => pointsDelta + ' points to go'}
                            />
                        }
                    </CardBody>
                </Card>
            </div>
        </>
    )
}
