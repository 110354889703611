
export default function WebflowHeader() {

    return (
<>
      <div class="navbar-logo-left">
    <div class="navbar-logo-left-container shadow-three w-nav">
      <div class="container-3">
        <div class="navbar-wrapper">
        <a href="https://www.friendlyfoodtracker.com" aria-current="page" className="brand w-nav-brand w--current"> <img src={require("../assets/images/logo_light_med.png")} alt="Small White FFT Logo" /></a>
          
          <div class="menu-button-2 w-nav-button">
            <div class="w-icon-nav-menu"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
      </>
    )
  }