// Profile
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { usePromiseTracker } from "react-promise-tracker";
import { useNavigate } from 'react-router-dom';
import { usePagination, useSortBy, useTable } from 'react-table';
import { UserAuth } from '../context/AuthContext';

import {
    initAmplitude, setAmplitudeUserId
} from '../context/AnalyticsContext';

import {
    Button
} from "@material-tailwind/react";

initAmplitude()

export default function FoodLookupTable(results) {
  const { promiseInProgress } = usePromiseTracker();
  const { user, logOut } = UserAuth();
  const [dailyTarget, setDailyTarget] = useState('')
  const [currentWeight, setCurrentWeight] = useState('')
  const [error, setError] = useState('')
  const [userProfile] = useState('')
  const [foodName, setFoodName] = useState('')
  const [showTable, setShowTable] = useState('invisible')
  const { register, handleSubmit, reset } = useForm();
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const  lookupResultsData = results.results

  setAmplitudeUserId(user.uid);

    const columns = useMemo(() => [
      {
        Header: 'Food Category',
        accessor: row => row.label
      },
      {
        Header: 'Food',
        accessor: row => row.value
      },
      {
        Header: 'Synonyms',
        accessor: row => row.synonyms,
        Cell: ({ value }) => value.join(', '),
      }
      ,
      {
        Header: 'Serving 1 Points',
        accessor: row => row.dqsPoints1,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

      },
      {
        Header: 'Serving 2',
        accessor: row => row.dqsPoints2,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'Serving 3',
        accessor: row => row.dqsPoints3,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'Serving 4',
        accessor: row => row.dqsPoints4,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'Serving 5',
        accessor: row => row.dqsPoints5,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: 'Serving 6+',
        accessor: row => row.dqsPoints6,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      }

    ], []);

    console.log('Results Component Table:', lookupResultsData)
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      //rows,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
      //prepareRow
    } = useTable(
      {
        columns,
        data: lookupResultsData,
        initialState: { pageSize: 5 }
      }, useSortBy, usePagination);

    return (

      <div className={`grid grid-cols-1 px-4 mb-16`}>

        {/* <CardHeader color="teal" className="text-left p-4">
                  <Typography>
                      All instances containg the phrase { foodName }
                  </Typography>
              </CardHeader> */}

        <div className="overflow-x-auto">
          <table {...getTableProps()} style={{ textTransform: 'capitalize' }} className="items-center w-full bg-transparent border-collapse">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    // Add the sorting props to control sorting. For this example
                    // we can add them into the header props
                    <th className="px-2 text-teal-500 align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left" {...column.getHeaderProps(column.getSortByToggleProps())}>

                      {column.render('Header')}
                      {/* Add a sort direction indicator */}

                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>

        </div>

        <div className="flex flex-1 justify-between pt-5">
          <Button color="teal" variant="outlined" onClick={() => previousPage()} disabled={!canPreviousPage}>Previous</Button>
          <p className="text-sm text-gray-700 ">
            <span className='mx-2'>
              Page{' '}
              {pageIndex + 1} of {pageOptions.length}
            </span>
            <span className='mx-2'>
              <select
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[5, 10, 20, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </span>

          </p>
          <Button color="teal" variant="outlined" onClick={() => nextPage()} disabled={!canNextPage}>Next</Button>
        </div>

      </div>


    );
  };