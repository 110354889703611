// Profile
import { Firestore } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form'
import { UserAuth } from '../context/AuthContext'
import DialogflowMessenger from '../components/DialogflowMessenger';
import {
  createUserProfile,
  displayUserSettings,
  updateUserProfile,
  addWeightValue,
} from '../functions/FirebaseCalls'

import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserId
} from '../context/AnalyticsContext';

import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Avatar,
  Input,
  Typography,
  Tabs,
  TabsHeader,
  TabPanel,
  TabsBody,
  Tab,
  Switch,
  Tooltip,
  Button,
} from "@material-tailwind/react";

initAmplitude()

const About = () => {
  const { user, logOut } = UserAuth();
  const [dailyTarget, setDailyTarget] = useState('')
  const [currentWeight, setCurrentWeight] = useState('')
  const [error, setError] = useState('')
  const [userProfile] = useState('')
  const [displayName, setDisplayName] = useState('')
  const { register, handleSubmit, reset } = useForm();
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();

  setAmplitudeUserId(user.uid);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/')
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.logOutButton.Clicked')
    }
  }

  const handleSave = async (e) => {
    try {
      e.preventDefault()
      setUserProfileData();
      setWeightValue();
      console.log('Values now saved')
    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.saveButton.Clicked')
    }
  }

  const setWeightValue = async () => {
    await addWeightValue(user.uid, currentWeight);
  }

  const setUserProfileData = async () => {
    await updateUserProfile(user.uid, displayName, dailyTarget, currentWeight);
  }



  useEffect(() => {
    const fetchData = async () => {
      if (user.uid) {
        const response = await displayUserSettings(user.uid)
        setDisplayName(response.display_name)
        setDailyTarget(response.target_points)
        setCurrentWeight(response.current_weight)
        setProfile({
          display_name: response.display_name,
          target_points: response.target_points,
          current_weight: response.current_weight,
        })
      }
    }
    fetchData();
  }, [user]); // Or [] if effect doesn't need props or state

  useEffect(() => {
    // reset form with user data
    reset(profile);
  }, [profile]);


  return (
    <>
      <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url(https://images.unsplash.com/photo-1568828668638-b1b4014d91a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2095&q=80)] bg-cover	bg-center">

      </div>
      <Card className="mx-3 -mt-16 mb-6 lg:mx-4">
        <CardBody className="p-4">
          <div className="flex items-center justify-between gap-6">
            <div className="flex items-center gap-6">
              <div>
                <Typography variant="h4" color="gray" className="mb-1">
                  About Diet Quality Score (DQS)
                </Typography>
                <Typography
                  variant="medium"
                  className="font-normal text-secondary"
                >
                  Learn more about DQS here. Feel free to get in touch if you've got more questions...
                </Typography>
              </div>
            </div>
          </div>
          <div className='max-w-[600px]mx-left p-4 text-blue-gray-600'>
          <Typography
                variant="normal"
                className="font-normal text-gray-600 leading-tight"
              >
              Diet quality score ('DQS') is a concept introduced by author and nutritionist Matt Fitzgerald in his book "Racing Weight: How to Get Lean for Peak Performance" which assigns a numerical value assigned to a person's diet based on its nutritional quality.
              <br/><br/>
              This score is used as a measure of how healthy a person's diet is rather than the traditional method of counting calories.
              <br/><br/>
              With DQS, foods are categorised into different food groups with around half being ‘High Quality’ and the remainder being ‘Low Quality’.  Whenever you eat foods of high quality, you’ll be awarded points. Similarly whenever you eat lower quality foods, points will be deducted.
              <br/><br/>The scoring takes into account the number of servings you have of the food categories each day and points can vary. For example, Fruits are classified as High Quality but by the fifth serving, the points awarded will have dropped from two to zero.
              <br/><br/>
              The two tables below show the points awarded per serving for the food groups.
              <br/><br/>
              From experience, DQS feels like a better approach than calorie counting and that’s why we’ve used it for Friendly Food Tracker…
              <br/><br/>
              Calorie counting is obvioulsy the most common method for managing weight but it has some limitations. It doesn't take into account the nutritional value of the foods consumed and it can be difficult to accurately estimate the number of calories in some foods. There’s also the negative mindset; cutting calories is a limiting behaviour and hard to maintain over time.
              <br/><br/>In contrast, a diet quality score considers the overall nutritional value of a person's diet, and - we feel - is a better way of assessing the healthiness of a person's eating habits. Think of your DQS score  like holding a mirror up to your diet!
              <br/><br/>
              But to be honest, where DQS really stands out over calorie counting is its simplicity. Using DQS we don't need to weigh food or fret over specific quantities. Instead, we simply track the type of food from the table below and use portion size as a gauge of quantity.
              
              </Typography>    
           

            <Card className='my-20'>
              <CardHeader className="text-left text-light p-4 bg-primary">
                <Typography variant="h4">
                  High Quality Foods
                </Typography>
              </CardHeader>
              <CardBody>
                <div className="overflow-x-auto">
                  <table style={{ textTransform: 'capitalize' }} className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-2 text-primary align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Food
                        </th>
                        <th className="px-2 text-primary align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 1
                        </th>
                        <th className="px-2 text-primary align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 2
                        </th>
                        <th className="px-2 text-primary align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 3
                        </th>
                        <th className="px-2 text-primary align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 4
                        </th>
                        <th className="px-2 text-primary align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 5
                        </th>
                        <th className="px-2 text-primary align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 6
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Serving
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Fruit
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Nuts, Seeds, etc
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                      </tr>
                      <tr>                            <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                        Whole Grains
                      </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Dairy
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          High Quality Meat & Seafood
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          High Quality Drinks
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td></tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          High Quality Processed Foods
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          +0
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>


                    </tbody>
                  </table>

                </div>
              </CardBody>

            </Card>

            <Card>
              <CardHeader className="bg-pinky text-light text-left p-4">
                <Typography variant="h4">
                  Low Quality Foods
                </Typography>
              </CardHeader>
              <CardBody>
                <div className="overflow-x-auto">
                  <table style={{ textTransform: 'capitalize' }} className="items-center w-full bg-transparent border-collapse">
                    <thead>
                      <tr>
                        <th className="px-2 text-pinky align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Food
                        </th>
                        <th className="px-2 text-pinky align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 1
                        </th>
                        <th className="px-2 text-pinky align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 2
                        </th>
                        <th className="px-2 text-pinky align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 3
                        </th>
                        <th className="px-2 text-pinky align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 4
                        </th>
                        <th className="px-2 text-pinky align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 5
                        </th>
                        <th className="px-2 text-pinky align-middle border-b border-solid border-gray-200 py-3 text-sm whitespace-nowrap font-light text-left">
                          Serving 6
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Refined Grains
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Sweets
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Processed Meat
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>
                      <tr>                            <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                        Fried Foods
                      </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Low Quality Drinks
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>
                      <tr>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          Other
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -1
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                        <td className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                          -2
                        </td>
                      </tr>


                    </tbody>
                  </table>

                </div>
              </CardBody>

            </Card>

          </div>
        </CardBody>
      </Card>

      <DialogflowMessenger/>
    </>
  )
}

export default About