import { useLocation } from 'react-router-dom';
import {
    IconButton,
    NavbarInput,
    Image,
    Dropdown,
    DropdownItem
} from "@material-tailwind/react";
import { Icon } from '@iconify/react';
import { Button } from '@mui/material';

export default function AdminNavbar({ showSidebar, setShowSidebar }) {
    const location = useLocation().pathname;

    return (
        <nav className="bg-secondary h-24 px-3">
            <div className="container max-w-full flex items-center md:pr-8 md:pl-10">
                <div className="md:hidden">
                    <Button
                        buttontype="link"
                        size="md"
                        icononly="true"
                        rounded="true"
                        ripple="light"
                        onClick={() => setShowSidebar('left-0')}
                    >
                        <Icon icon="material-symbols:menu" width="24" color="white" />


                    </Button>
                    <div
                        className={`absolute top-2 md:hidden ${showSidebar === 'left-0' ? 'left-64' : '-left-64'
                            } z-50 transition-all duration-300`}
                    >
                        <Button
                            buttontype="link"
                            size="md"
                            icononly="true"
                            rounded="true"
                            ripple="light"
                            onClick={() => setShowSidebar('-left-64')}
                        >
                            <Icon icon="material-symbols:close" width="24" color="white" />
                        </Button>
                    </div>
                </div>


                    <div className='flex items-center justify-start ml-5 h-24'>
                        <h4 className="uppercase text-light text-md tracking-wider mt-1">
                            {location === '/'
                                ? 'SIGN IN'
                                : location === '/about'
                                ? 'DIET QUALITY SCORE (DQS)'
                                : location === '/whatsapp'
                                ? 'WHATSAPP SETTINGS'
                                : location.toUpperCase().replace('/', '').replace('-', ' ')}
                        </h4>

                   


                </div>
            </div>
        </nav>
    );
}
