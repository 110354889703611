import React from "react";
import { ThreeDots } from 'react-loader-spinner';
import MessageParser from "./MessageParser";
import { UserAuth } from '../../context/AuthContext';


class ActionProvider {
  constructor(createChatBotMessage, setStateFunc, createClientMessage) {
    this.messageParser = MessageParser;
    this.createChatBotMessage = createChatBotMessage;
    this.createClientMessage = createClientMessage;
    this.setState = setStateFunc;
    this.isLoading = false;
    this.loadingMessage = null;
    this.messageParser = new MessageParser(this);
    this.user = UserAuth();
    

  }

  setLoading = (loading) => {
    this.isLoading = loading;
    if (this.isLoading) {
      this.showLoadingMessage();
    } else {
      this.hideLoadingMessage();
    }
  };

  showLoadingMessage = () => {
    if (!this.loadingMessage) {
      const message = this.createChatBotMessage(
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto' }}>
          <ThreeDots type="ThreeDots" color="#fafafa" height="30" width="30" />
        </div>,
        { withAvatar: true }
      );
      this.loadingMessage = message;
      this.addMessageToState(message);
    }
  };

  hideLoadingMessage = () => {
    if (this.loadingMessage) {
      this.removeMessageFromState(this.loadingMessage);
      this.loadingMessage = null;
    }
  };

  setBotResponse = (message) => {
    console.log('Received message:', message);

    console.log('Message Payload:', message);

    if (!message || message.length === 0) {
      return null;
    }

    const formattedMessages = message.map((msg) => {
      console.log('Message type:', msg.type);
      console.log('Message content:', msg);

      if (msg.type === 'image') {
        const maxWidth = window.innerWidth <= 640 ? '200px' : '400px';

        return this.createChatBotMessage(

          <img src={msg.src} alt="chatbot image" style={{ maxWidth }} />, {
          withAvatar: true,
        });
      } else if (msg.type === 'text') {

        let res = msg.message
        res = res.replace(/\n/g, '<br>');

        console.log('res: ', res )
        
        return this.createChatBotMessage(
           
          <div dangerouslySetInnerHTML={{ __html: res }} />,
          { withAvatar: true }
        );
      }

      return null;
    });
    console.log('Formatted messages:', formattedMessages);
    this.setState((state) => ({
      ...state,
      messages: [...state.messages, ...formattedMessages],
    }));
  };

  chooseFood = () => {
    const message = this.createChatBotMessage(
      "No problem, what would you like to log?"
    );
    this.addMessageToState(message);
  };

  sendFeedback = () => {
    const message = "Give Feedback";
    this.messageParser.parse(message);
  };

  addMessageToState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: [...prevState.messages, message]
    }));
  };

  removeMessageFromState = (message) => {
    this.setState((prevState) => ({
      ...prevState,
      messages: prevState.messages.filter((msg) => msg !== message)
    }));
  };
}

export default ActionProvider;
