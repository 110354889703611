import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { UserAuth } from '../../context/AuthContext';
import { ThreeDots } from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import StatusCard from '../../components/StatusCard';


export default function SummaryPointsSingleStat() {
    const { promiseInProgress } = usePromiseTracker();
    const [pointsDailyTotal, setPointsDailyTotal] = useState(0);
    const [pointsWeeklyTotal, setPointsWeeklyTotal] = useState(0);
    const [pointsMonthlyTotal, setPointsMonthlyTotal] = useState(0);

    const { user } = UserAuth();

    useEffect(() => {
        if (user.accessToken) {
            const token = user.getIdToken()
            const fetchRawData = async () => {

                const axios_config = {
                    method: 'GET',
                    url: process.env.REACT_APP_API_URL + `/api/v1/summary-points-singlestat`,
                    headers: {
                        "Authorization": `Bearer ${user.accessToken}`
                    },
                    params: {
                        timezoneOffset: new Date().getTimezoneOffset(),
                    },
                  };

                const response = await trackPromise(axios(axios_config))

                const data = response.data;

                console.log("Summary Points Singlestat:", data)

                setPointsDailyTotal(data[0].points_daily_total);
                setPointsWeeklyTotal(data[0].points_weekly_total);
                setPointsMonthlyTotal(data[0].points_monthly_total);

            }
            // Fetch 'feed' data then aggregate it
            fetchRawData();
        }
    }, [user]);


    const LoadingIndicator = props => {
        return (
            promiseInProgress &&
            <div
                style={{
                    width: "100%",
                    height: "100",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}
            >
                <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
            </div>
        );
    };

    return (
        <>
            <div className="container max-w-full">
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mb-4">
                    <StatusCard
                        color="pink"
                        icon="mdi:calendar-day-outline"
                        title="Today's Points"
                        amount={pointsDailyTotal}
                    />

                    <StatusCard
                        color="purple"
                        icon="mdi:calendar-week-outline"
                        title="This Week's Points"
                        amount={pointsWeeklyTotal}
                    />
                    <StatusCard
                        color="orange"
                        icon="mdi:calendar-month-outline"
                        title="Last 4 Week's Points"
                        amount={pointsMonthlyTotal}
                    />
                </div>
            </div>

        </>
    )
}
