// Profile
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { ThreeDots } from 'react-loader-spinner';
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import { InlineIcon } from '@iconify/react';
import DialogflowMessenger from '../components/DialogflowMessenger';
import FoodLookupTable from '../components/FoodLookupTable';

import {
  initAmplitude,
  sendAmplitudeData,
  setAmplitudeUserId
} from '../context/AnalyticsContext';

import {
  Button, Card,
  CardBody, Input,
  Typography
} from "@material-tailwind/react";

initAmplitude()

const Lookup = () => {
  const { promiseInProgress } = usePromiseTracker();
  const { user, logOut } = UserAuth();
  const [dailyTarget, setDailyTarget] = useState('')
  const [currentWeight, setCurrentWeight] = useState('')
  const [error, setError] = useState('')
  const [userProfile] = useState('')
  const [foodName, setFoodName] = useState('')
  const [showTable, setShowTable] = useState('invisible')
  const { register, handleSubmit, reset } = useForm();
  const [profile, setProfile] = useState(null);
  const navigate = useNavigate();
  const [lookupResultsData, setLookupResultsData] = useState([]);

  setAmplitudeUserId(user.uid);

  const handleFoodLookup = async (e) => {
    try {
      e.preventDefault()
      foodLookup();
      console.log('Food Lookup')
    } catch (e) {
      console.log(e.message)
    } finally {
      setShowTable('visible');
      sendAmplitudeData('profile.foodLookupButton.Clicked')
    }
  }

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/')
      console.log('You are logged out')
    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.logOutButton.Clicked')
    }
  }

  const handleSave = async (e) => {
    try {
      e.preventDefault()
      console.log('Values now saved')
    } catch (e) {
      console.log(e.message)
    } finally {
      sendAmplitudeData('profile.saveButton.Clicked')
    }
  }


  const foodLookup = async () => {
    if (user.accessToken) {
      const token = user.getIdToken()

      const response = await trackPromise(
        axios.get(
          process.env.REACT_APP_API_URL + `/api/v1/food-lookup`, 
          { 
            params: { food: foodName.toLowerCase() }, 
            headers: { 
              "Authorization": `Bearer ${user.accessToken}` 
            } 
          }))
      const data = await response.data;

      console.log('Food Lookup: ', data);

      setLookupResultsData(data);
    }
  }

  const LoadingIndicator = props => {
    return (
      promiseInProgress &&
      <div
        style={{
          width: "100%",
          height: "100",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <ThreeDots type="ThreeDots" color="#54b6bb" height="100" width="100" />
      </div>
    );
  }

  return (
    <>
      <div className="relative mt-8 h-72 w-full overflow-hidden rounded-xl bg-[url(https://images.unsplash.com/photo-1510812431401-41d2bd2722f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)] bg-cover bg-center">

      </div>
      <Card className="mx-3 mb-6 lg:mx-4">
        <CardBody className="p-4 bg-light">
          <div className="flex items-center justify-between gap-6">
            <div className="flex items-center gap-6">
              <div>
              <Typography variant="h4" color="gray" className="mb-1">
                  Food Lookup
                </Typography>
                <Typography
                  variant="medium"
                  className="font-normal text-secondary"
                >
                  Find out which DQS category your food belongs to here...
                </Typography>
              </div>
            </div>
          </div>

          <div className='max-w-[600px] mx-left p-4 text-blue-gray-600'>
            <form>
              
              <Typography
                variant="small"
                className="font-normal text-gray-600"
              ></Typography>
              <label className='font-medium'></label>
              <div className='flex flex-col'>
                {/* <label className='py-2 font-medium'>Display Name:</label> */}
                <Input color='teal' className='focus:outline-none hover:outline-primary active:outline-primary active:border-none' {...register('food')} onChange={(e) => setFoodName(e.target.value)} label="Enter Food Here" />
                <Button onClick={handleFoodLookup} className='bg-primary hover:opacity-80 w-full my-2 text-white'>Search</Button>
              </div>
              
            </form>

          </div>

          <div className={`${showTable}`}>
            {
              (promiseInProgress === true) ? <LoadingIndicator /> : (lookupResultsData.length === 0) ? <div className="bg-blue-100 border border-blue-400 text-blue-700 px-4 py-3 rounded relative" role="alert">
                <span className="block sm:inline">No results returned</span>
                <span className="absolute top-0 bottom-0 right-0 px-4 py-3">

                </span>
              </div> : <FoodLookupTable results={lookupResultsData} />

            }
          </div>
          <div className="bg-primary   border-t-4 border-pinky rounded-b text-light px-4 mt-5 py-1 shadow-md" role="alert">

                <div className="font-bold" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <InlineIcon icon="mdi:information-outline" className='mr-3' />We need your feedback
              </div>
              <p className="text-sm">If you can't find a particular food or you feel the points being awarded maybe incorrect in your opinion, please get in touch.</p>
              </div>
        </CardBody>
      </Card>

      <DialogflowMessenger/>
    </>
  )
}

export default Lookup