import React from 'react';
import LogIn from './pages/LogIn';
import SignUp from './pages/SignUp';
import PasswordReset from './pages/PasswordReset';
import PasswordResetConfirmation from './pages/PasswordResetConfirmation';
import EmailVerification from './pages/EmailVerification';
import Account from './pages/Account';
import Contact from './pages/Contact';
import Profile from './pages/Profile';
import WhatsApp from './pages/WhatsApp';
import DeleteAccount from './pages/DeleteAccount';
import Dashboard from './pages/Dashboard';
import Chat from './pages/Chat';
import About from './pages/About';
import Lookup from './pages/Lookup';
import { Route, Routes } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Sidebar from './components/Sidebar';
import { ThemeProvider } from "@material-tailwind/react";
import WebflowFooter from './components/WebflowFooter';


function App() {
  return (
    <>
      <AuthContextProvider>
        <Routes>Routes
          <Route path='/' element={<LogIn />} />
          <Route path='/login' element={<LogIn />} />
          <Route path='/signup' element={<SignUp />} />
          <Route path='/password-reset' element={<PasswordReset />} />
          <Route path='/password-reset-confirmation' element={<PasswordResetConfirmation />} />
          <Route path='/email-verification' element={<EmailVerification />} />
          <Route path='/account' element={
            <ProtectedRoute>
              <Account />
            </ProtectedRoute>
          } />
          <Route path='/profile' element={
            <ProtectedRoute>
              <Sidebar />
              <div className="md:ml-64">
                <Profile />
              </div>
              <div className='p-0 xl:ml-24 md:w-full'>
                <WebflowFooter />
              </div>
            </ProtectedRoute>
          } />
          <Route path='/whatsapp' element={
            <ProtectedRoute>
              <Sidebar />
              <div className="md:ml-64">
                <WhatsApp />
              </div>
              <div className='p-0 xl:ml-24 md:w-full'>
                <WebflowFooter />
              </div>

            </ProtectedRoute>
          } />
          <Route path='/dashboard' element={
            <ProtectedRoute>
              <Sidebar />
              <div className="md:ml-64">
                <Dashboard />
              </div>
              <div className='p-0 xl:ml-24 md:w-full'>
                <WebflowFooter />
              </div>

            </ProtectedRoute>
          } />
          <Route path='/chat' element={
            <ProtectedRoute>
              <Sidebar />
              <div className="md:ml-64">
                <Chat />
              </div>
            </ProtectedRoute>
          } />
          <Route path='/about' element={
            <ProtectedRoute>
              <Sidebar />
              <div className="md:ml-64">
                <About />
              </div>
              <div className='p-0 xl:ml-24 md:w-full'>
                <WebflowFooter />
              </div>
            </ProtectedRoute>
          } />
          <Route path='/lookup' element={
            <ProtectedRoute>
              <Sidebar />
              <div className="md:ml-64">
                <Lookup />
              </div>
              <div className='p-0 xl:ml-24 md:w-full'>
                <WebflowFooter />
              </div>
            </ProtectedRoute>
          } />
          <Route path='/contact' element={
            <ProtectedRoute>
              <Sidebar />
              <div className="md:ml-64">
                <Contact />
              </div>
              <div className='p-0 xl:ml-24 md:w-full'>
                <WebflowFooter />
              </div>
            </ProtectedRoute>
          } />
          <Route path='/delete-account' element={
            <ProtectedRoute>
              <Sidebar />
              <div className="md:ml-64">
                <DeleteAccount />
              </div>
              <div className='p-0 xl:ml-24 md:w-full'>
                <WebflowFooter />
              </div>
            </ProtectedRoute>
          } />
        </Routes>
      </AuthContextProvider>


    </>
  );
}

export default App;
