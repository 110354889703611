import React from "react";
import { createChatBotMessage } from "react-chatbot-kit";

//import Genre from "../components/Genre";
import InitialOptions  from "./options/Options.js";


const botName = "Lotty";

const config = {
  botName: 'Lottie',
    initialMessages: [
      createChatBotMessage('Hi Morpheus, how can I help you today?'),
      createChatBotMessage("initialMessgage", {
        withAvatar: true,
        widget: "initialOptions"
      })
    ],
  customComponents: {
    botAvatar: (props) => (
      <img
        src="https://storage.googleapis.com/lhotse-server.appspot.com/male_green_48x48.png"
        style={{ width: '48px', height: '48px', marginRight: '15px' }}
      />
    ),
    userAvatar: (props) => (
      <img
        src="https://storage.googleapis.com/lhotse-app-gcp.appspot.com/blank-profile48x48.png"
        alt="user avatar"
        style={{ width: '48px', height: '48px', marginLeft: '15px' }}
      />
    ),
    
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: `rgb(135, 143, 172, 0.9)`
    },
    chatButton: {
      backgroundColor: '#54b6bb',
    },
  },
  widgets: [
    {
      widgetName: "initialOptions",
      widgetFunc: (props) => <InitialOptions {...props} />
    }
  ]
};

export default config;
